/* Reset Password Styles */
.ResetPasswordModalGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 40px 80px 80px 80px 80px 80px 80px 80px 80px;
  text-transform: capitalize;
  width: 600px;
  height: 220px;
}

.ResetPasswordDialogTitle {
  text-align: center;
  grid-row: 1;
  grid-column: 1 / span 16;
  align-self: center;
  font-weight: 800;
  font-size: 24px;
}

.ResetPasswordRunwaySupplyIcon {
  grid-row: 1;
  grid-column: 4 / span 4;
  align-self: center;
}

.ResetPasswordRunwaySupplyIconWidth {
  width: 48px;
}

.ResetPasswordDialogContent {
  grid-row: 2;
  grid-column: 4 / span 10;
  align-self: center;
}

.ResetPasswordDialogActions {
  grid-row: 3;
  grid-column: 4 / span 10;
  padding-top: 15px;
}

.ResetPasswordDialogActions button {

  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
  text-transform: uppercase;
}

/* forgot password media  queries */

@media only screen and (max-width: 600px) {
  .ResetPasswordModalGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 80px 80px 80px 80px 80px 80px 80px 80px 80px;
    text-transform: uppercase;
    width: calc(100vw - 40px);
    height: 300px;
  }

  .ResetPasswordRunwaySupplyIconWidth {
    width: 44px;
  }

  .ResetPasswordRunwaySupplyIcon {
    grid-row: 1;
    grid-column: 1 / span 3;
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: 20px;
  }

  .ResetPasswordDialogTitle {
  }

  .ResetPasswordDialogContent {
    grid-row: 2;
    grid-column: 1 / span 16;
  }

  .ResetPasswordDialogActions {
    grid-column: 1 / span 16;
  }

  .ResetPasswordDialogActions button {
    background: #ff4742;
    font-weight: 800;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff4742;
    display: inline-block;
    padding: 14px;
    display: block;
    border-radius: 5px;
    width: 100%;
  }
}
