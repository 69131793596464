.NavbarContainer {
  margin-top: 30px;
  display:grid;
  grid-template-columns: repeat(16, 1fr);
  align-items: center;
}

.NavbarContainer div ,a, a:visited, a:hover, a:focus {
  text-decoration: none;
  color:black;
  text-transform: uppercase;
}

.Logo {
  grid-column: 2 / span 2;
  grid-row: 1;
  font-weight: bold;
  white-space: nowrap;
}

.LinkToCampaigns {
  grid-column: 8 / span 2;
}

.About {
  grid-column: 5 / span 1;
}

.NavCampaigns {
  grid-column: 9 / span 1;
}

.Features {
  grid-column: 11 / span 1;
  cursor: pointer;
}

.Pricing {
  grid-column: 13 / span 1;
  cursor: pointer;
}

.ComingSoon {
  grid-column: 15 / span 1;
}

.bm-burger-button {
  display:none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  display:none;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  display:none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display:none;
}

/* Color/shape of close button cross */
.bm-cross {
  display:none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  display:none;
}

/* General sidebar styles */
.bm-menu {
  display:none;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  display:none;
}

/* Wrapper for item list */
.bm-item-list {
  display:none;
}

/* Individual item */
.bm-item {
    display:none;
}

/* Styling of overlay */
.bm-overlay {
  display:none;
}


/*NAVIGATION MEDIA QUERIES */

@media only screen and (max-width: 1100px)  {
    .ComingSoon {
      grid-column: 15/ span 2;
    }
}

@media only screen and (max-width: 600px)  {
  .NavbarContainer {
    display:grid;
    grid-template-columns: repeat(16, 1fr);
    font-size: 14px;
  }

  .Logo {
    grid-column: 2 / span 2;
    grid-row: 1;
  }

  .About {
    grid-column: 8 / span 1;
  }

  .NavCampaigns {
    grid-column: 10 / span 4;
    grid-row: 3;
    margin-top: 24px;
  }

  .Features {
    grid-column: 8 / span 1;
    grid-row: 3;
    margin-top: 24px;
  }

  .Pricing {
    grid-column:  6 / span 1;
    grid-row: 3;
    margin-top: 24px;
  }

  .ComingSoon {
    grid-column: 11 / span 4;
  }

 }


.AuthenticatedNavbarContainer {
  background-color: #24292E;
  display:grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 70px;
  align-items: center;
}

.AuthenticatedNavbarContainer div a{
  color: white;
}

.AuthenticatedNavbarContainer .Logo {
  grid-column: 2 / span 2;
  font-weight: bold;
  white-space: nowrap;
}

.CampaignsLink {
  grid-column: 7 / span 1;
  grid-row: 1;
}

.NetworkLink {
  grid-column: 9 / span 1;
  grid-row: 1;
}

.ProfileLink {
  grid-column: 10 / span 2;
}

.NotificationsLink {
  grid-column: 14 / span 1;
}

.NavPhoto {
  grid-column: 15 / span 1;
}

.NavPhoto img{
  border-radius: 50%;
  object-fit: cover;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
}

.Logout {
  grid-column: 16;
}

.Notifications-Modal-Container {
  display: none;
  background-color: #fff;
  grid-template-columns: repeat(1,max-content);
  border: 1px solid black;
  position: absolute;

  z-index:1000;
  grid-auto-rows: auto;
  grid-row-gap: 5px;
  top: 60px;
  right: 30px;
}

.RunwaySupplyMobileHeaderLink {
  display:none;
}


@media only screen and (max-width: 600px)  {
  #Selected-Link {
    display:none;
  }
    .AuthenticatedNavbarContainer {
      background-color: #24292E;
      display:grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 70px;
      align-items: center;
    }

    .AuthenticatedNavbarContainer div a{
      color: white;
    }

    .AuthenticatedNavbarContainer .Logo {
      grid-column: 2 / span 2;
      font-weight: bold;
      white-space: nowrap;
      display: none;
    }

    .CampaignsLink {
      grid-column: 1;
      justify-self: center;
      display: none;
    }

    .NetworkLink {
      grid-column: 2;
      justify-self: flex-end;
      display: none;
    }

    .ProfileLink {
      display: none;
    }

    .NotificationsLink {
      grid-row: 1;
      grid-column: 1 / span 2;
      justify-self:flex-end;
      padding-right: 30px;
      }

    .RunwaySupplyMobileHeaderLink {
      grid-row:1;
      display: block;
      grid-column: 1 / span 2;
      justify-self:center;
      font-size: 18px;
      color:white;
      letter-spacing: 1px;
    }

    .NavPhoto {
      grid-column: 4;
      display:none;

    }

    .NavPhoto img{
      border-radius: 50%;
      object-fit: cover;
      width: 40px;
      height: 40px;
    }

    .BrandLogoMobile {
      border-radius: 50%;
      object-fit: cover;
      width:80px;

      height: 80px;

    }

    .Logout {
      grid-column: 16;
    }

    /* Position and sizing of burger button */
.bm-burger-button {
    display: block;
    position: absolute;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 24px;
    color:white;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    display: block;
    background-color: #c3c3c3;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    display: block;

}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    display: block;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  display: block;
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  display: block;
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  display: block;
  background: #24292E;;
  font-size: 1.15em;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  display: block;
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  display: block;
  color: #b8b7ad;

}

/* Individual item */
.bm-item {
  display: block;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.Hamburger-Navigation-Links {
  display: grid;
  grid-template-rows: 120px 70px 70px 70px 70px 70px;
  padding-top: 40px;

}

.Hamburger-Navigation-Links-Border-Item-One {
  padding-left: 10px;

}

.Hamburger-Navigation-Links-Border-Item-General {
  padding-left: 10px;
}

.Hamburger-Navigation-Links-Border-Item-Last {
  padding-left: 10px;
}

  .Notifications-Modal-Container {
    display: none;
    background-color: #fff;
    grid-template-columns: repeat(1,max-content);
    border: 1px solid black;
    position: absolute;
    justify-self:center;
    z-index:1000;
    grid-auto-rows: auto;
    grid-row-gap: 5px;
    top: 70px;
  }

 }
