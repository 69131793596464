.ModalGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 80px 80px 80px 80px 80px 80px 50px 30px 80px;
  text-transform: uppercase;
  width: 600px;
}

.DialogTitle {
  text-align: center;
  grid-row: 1;
  grid-column: 1 / span 16;
  align-self: center;
  font-weight: 800;
  font-size: 24px;
}

.RegistrationRunwaySupplyIcon {
  grid-row: 1;
  grid-column: 4 / span 4;
  align-self: center
}

.RunwaySupplyIconWidth {
  width: 44px;
}

.DialogContent {
  grid-row: 2 / span 6;
  grid-column: 4 / span 10;
}

.DialogContent input {
  appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  padding: 7px;
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: calc(100% - 14px);
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}

/* This Container Holds Our Input and Icon Its on A div */
.DialogContentInputContainer {
  display: grid;
  grid-column: 1 / span 16;
  grid-template-columns: 60px 4fr;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  height:45px;
}

.DialogContentInputContainer:focus-within input {
  grid-column: 1/4;
  padding-left: 55px;
}

.DialogContentInputContainer:focus-within input::placeholder {
  color: transparent;
}

.DialogContentInputContainer img {
  padding: 0.5rem;
  grid-row: 1;
  grid-column: 1/2;
  z-index: 2;
}

.DialogContentInputContainer input {
  border: 0;
  padding: 0.5rem;
  grid-column: 2/4;
  grid-row: 1;
  outline: none;
  font-size: 16px;
}

.DialogContent ::-webkit-input-placeholder {
  padding-right: 60px;
  text-align: center;
}

.DialogContent :-moz-placeholder {
  text-align: center;
}

.DialogContent ::-moz-placeholder {
  text-align: center;
}

.DialogContent :-ms-input-placeholder {
  text-align: center;
}

.CardContainer {
  appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  padding: 14px;
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-top: 20px;
  max-width: -webkit-fill-available;
}

.DialogActions {
  grid-row: 7;
  grid-column: 4 / span 10;
  position:relative;
  height:47px;
}

.DialogActions button {
  text-transform: uppercase;
  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.DialogContentTextLogin {
  grid-row: 8;
  grid-column: 1 / span 16;
  text-align: center;
  font-size: 14px;
  color: #afafaf;
}

.DialogContentTextLogin span {
  text-decoration: underline;
}

.DialogContextTextSignup {
  grid-row: 9;
  grid-column: 4 / span 10;
  font-size: 10px;
  text-align: center;
  color: #afafaf;
}

.DialogContextTextSignup span {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .ModalGrid {
    width: auto;
  }

  .DialogTitle {
    text-align: center;
    grid-row: 1 / span 2;
    grid-column: 1 / span 16;
    align-self: center;
  }

  .RunwaySupplyIconWidth {
    width: 44px;
  }

  .RegistrationRunwaySupplyIcon {
    grid-row: 1 / span 2;
    grid-column: 1 / span 3;
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: 20px;
  }

  .DialogTitle {
    text-align: center;
    grid-row: 1;
    grid-column: 4 / span 10;
  }

  .DialogContent {
    grid-row: 2 / span 5;
    grid-column: 1 / span 16;
  }

  .CardContainer {
    appearance: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding: 7px;
    padding-top: 19px;
    padding-bottom: 19px;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.23);
    max-width: -webkit-fill-available;
  }

  .DialogActions {
    grid-row: 7;
    grid-column: 1 / span 16;
  }

  .DialogActions button {
    background: #ff4742;
    font-weight: 800;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff4742;
    display: inline-block;
    padding: 14px;
    display: block;
    border-radius: 5px;
    width: 100%;
  }

  .DialogContentTextLogin {
    font-size: 12px;
  }

  /*
     These weird classnames are the classnames of the modal so I make the modal take the full height
     and width when we are in mobile
   */
  .styles_overlay__CLSq- {
    width: 100%;
    padding: 0;
  }

  .styles_modal__gNwvD {
    height: 100%;
  }
}

/* LOGIN */
.LoginModalGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: max-content max-content max-content, max-content max-content;
  grid-row-gap: 20px;
  text-transform: uppercase;
  width: 600px;
  height: 300px;
}

.LoginDialogTitle {
  text-align: center;
  grid-row: 1;
  grid-column: 1 / span 16;
  align-self: center;
  font-weight: 800;
  font-size: 24px;
}

.LoginRunwaySupplyIcon {
  grid-row: 1;
  grid-column: 4;
  align-self: center;
}

.LoginRunwaySupplyIconWidth {
  width: 48px;
}

.LoginDialogContent {
  grid-row: 2 / span 2;
  grid-column: 4 / span 10;
}

.LoginDialogActions {
  grid-row: 3;
  grid-column: 4 / span 10;
}

.LoginDialogActions button {
  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
}

.RedButton {
  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
}

.MiniSubHeaderForModal {
  grid-column: 1 / span 16;
  text-align: center;
  font-size: 14px;
  color: #afafaf;
}

.MiniSubHeaderForModal span {
  text-decoration: underline;
}

.DialogContentTextForgotPassword {
  grid-row: 4;
  grid-column: 1 / span 16;
  text-align: center;
  font-size: 14px;
  color: #afafaf;
  align-self: center;
  padding-top: 35px;
}

.DialogContentTextForgotPassword span {
  text-decoration: underline;
}

.LoginDialogContent {
  grid-row: 2 / span 6;
  grid-column: 4 / span 10;
}

.LoginInputColumnHandler {
  grid-column: 4 / span 10;
}
/* login media queries */

@media only screen and (max-width: 600px) {
  .LoginModalGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: auto auto auto auto auto;
    text-transform: uppercase;
    width: calc(100vw - 40px);
    height: 300px;
    grid-row-gap: 10px;
  }

  .LoginInputColumnHandler{
    grid-column:1 / span 16;
  }

  .LoginRunwaySupplyIconWidth {
    width: 44px;
  }

  .LoginRunwaySupplyIcon {
    grid-row: 1;
    grid-column: 1 / span 3;
    justify-self: flex-star;
    align-self: flex-start;
    margin-top: 20px;
  }

  .LoginDialogTitle {
    text-align: center;
  }

  .LoginDialogContent {
    grid-row: 2;
    grid-column: 1 / span 16;
  }

  .LoginDialogActions {
    grid-column: 1 / span 16;
  }

  .LoginDialogActions button {
    background: #ff4742;
    font-weight: 800;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff4742;
    display: inline-block;
    padding: 14px;
    display: block;
    border-radius: 5px;
    width: 100%;
  }

  .DialogContentTextForgotPassword {
    font-size: 12px;
  }
}

/* forgotpassword modal styles */

.ForgotPasswordModalGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 80px 80px 80px 80px 80px 80px 80px 80px 80px;
  text-transform: uppercase;
  width: 600px;
  height: 250px;
}

.ForgotPasswordDialogTitle {
  text-align: center;
  grid-row: 1;
  grid-column: 1 / span 16;
  align-self: center;
  font-weight: 800;
  font-size: 24px;
}

.ForgotPasswordRunwaySupplyIcon {
  grid-row: 1;
  grid-column: 4 / span 4;
  align-self: center;
}

.ForgotPasswordRunwaySupplyIconWidth {
  width: 48px;
}

.ForgotPasswordDialogContent {
  grid-row: 2;
  grid-column: 4 / span 10;
}

.ForgotPasswordDialogActions {
  grid-row: 3;
  grid-column: 4 / span 10;
}

.ForgotPasswordDialogActions button {
  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
}

/* forgot password media  queries */

@media only screen and (max-width: 600px) {
  .ForgotPasswordModalGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 80px 80px 80px 80px 80px 80px 80px 80px 80px;
    text-transform: uppercase;
    width: calc(100vw - 40px);
    height: 300px;
  }

  .ForgotPasswordRunwaySupplyIconWidth {
    width: 44px;
  }

  .ForgotPasswordRunwaySupplyIcon {
    grid-row: 1;
    grid-column: 1 / span 3;
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: 20px;
  }

  .ForgotPasswordDialogTitle {
    text-align: center;
  }

  .ForgotPasswordDialogContent {
    grid-row: 2;
    grid-column: 1 / span 16;
  }

  .ForgotPasswordDialogActions {
    grid-column: 1 / span 16;
  }

  .ForgotPasswordDialogActions button {
    background: #ff4742;
    font-weight: 800;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff4742;
    display: inline-block;
    padding: 14px;
    display: block;
    border-radius: 5px;
    width: 100%;
  }
}
