.CampaignsContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #f6f8fa;
  grid-template-rows: 20px 90px max-content;
  min-height: 100vh;
  padding: 0px 0px 100px;
}

.CreateCampaign {
  grid-row: 2;
  grid-column: 13;
  width: 200px;
  background: #0097ff;
  overflow: hidden;
  height: 50px;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-column-gap: 12px;
  padding-left: 20px;
  align-items: center;

  color: white;
}

.CreateCampaign button {
  background: #0097ff;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;

  width: 100%;
}

.CampaignsThreeColumns {
  grid-row: 3;
  grid-column: 3 / span 12;
  display: grid;
  grid-template-columns: repeat(3, minmax(350px, 1fr));
  grid-gap: 50px 50px;
}

.CampaignBox {
  height: 350px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  grid-template-rows: 100px 200px 50px;
}

.CampaignStatistics {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 100px 100px;
}

.CampaignStatisticsViews {
  grid-row: 1;
  grid-column: 1 / span 8;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  align-items: center;
  border-left: none;
}

.CampaignStatisticsApplicants {
  grid-row: 1;
  grid-column: 9 / span 8;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  align-items: center;
  border-right: none;
}

.CampaignStatisticsShare {
  grid-row: 2;
  grid-column: 1 / span 8;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-left: none;
}

.CampaignStatisticsShareLink {
  overflow: hidden;
}

.CampaignStatisticsTextAreaShareLinkTextArea {
  height: 30px;
  font: normal 14px verdana;

  border: solid 0.1px #ddd;
  font-size: 12px;
  white-space: nowrap;
  background-color: #e6e6e6;
  overflow: hidden;
}

.CampaignStatiscsHireStatus {
  grid-row: 2;
  grid-column: 9 / span 8;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  align-items: center;
  border-right: none;
}

@media only screen and (max-width: 1100px) {
  .CampaignsThreeColumns {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-gap: 50px 50px;
  }
  .CreateCampaign {
    grid-row: 2;
    grid-column: 13;
    overflow: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .CampaignsThreeColumns {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 50px 50px;
  }
  .CreateCampaign {
    grid-row: 2;
    grid-column: 13;
    overflow: hidden;
  }
}
