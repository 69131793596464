


.HeaderNotFound {
  font-size: 24px;
}

@media only screen and (max-width: 320px) {
  .HeaderNotFound {
    font-size: 16px;
  }
}
