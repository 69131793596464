
.ProfileGridContainer {
  display: grid;
  grid-template-columns: repeat(16 , 1fr);
  grid-template-rows: 30px 250px 0px 300px 0px 300px;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .ProfileGridContainer {
    display: block;
  }
}

.ProfileInput {
  border: 1px solid black;
  overflow: hidden;
  margin-top: 10px;
  height: 40px;
  background-color: #fff;
  padding: 0px;
  padding-left: 10px;
}

.ProfileSaveInput {
  border: none;
  color:white;
  overflow: hidden;
  margin-top: 10px;
  height: 40px;
  background-color: #00cb93;
  width: 100%;
}

.ProfileDeleteInput {
  border: none;
  color:white;
  overflow: hidden;
  margin-top: 10px;
  height: 40px;
  background-color: #ff4d56;
  width: 100%;
}

.ProfileCardContainer {
  appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  padding: 14px;
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  max-width: -webkit-fill-available;
}
/*

display: grid;
grid-template-columns: 1fr;
grid-column: 1 / span 16;
border: 1px solid #ccc;
border-radius: 5px;
overflow: hidden;
margin-top: 10px;
height: 50px;
background: #fff;
z-index: 2;

*/
