input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: black !important;
  color: black !important;
}

.Dropdown-control {
  height: 40px !important;
  border: none !important;
  font-size: 12px;
}
.is-selected {
  color: black !important;
}
.Dropdown-placeholder {
  color: #828282;
  padding-right: 5px;
  padding-top: 5px;
  font-size: 12px;
}

.ApplicationGridContainer {
  display: grid;
  grid-template-rows: 170px 1fr;
  grid-row-gap: 12px;
}

.ApplicationCoverPhoto {
  overflow: hidden;
}

.ApplicationLeftContent {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-column: 1 / span 3;
  grid-template-rows: 60px max-content max-content max-content max-content;
  grid-row-gap: 15px;
  align-items: center;
}

.ApplicationMainContent {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-column: 4 / span 10;
  grid-row-gap: 30px;
  grid-auto-rows: min-content;
}

.MobileCampaignDetails {
  display: none;
}
/* .ReactCrop img {
  height: 100%;
  max-width: none;
  width: 100%;
}

.cropper-canvas {
  transform:none;
  transform: translateX(0px) !important;
  width: 100% !important;
  height: 100% !important;

}



.cropper-container {

}




*/

.cropper-canvas {
  max-width: 150px !important;
}

.maincropper div {
  width: 150px !important;
  height: 300px !important;
}

.cropper-canvas img {
}

.cropper-hidden {
  height: auto !important;
  width: auto !important;
}

.cropper-container {
  width: 150px !important;
  height: 300px !important;
}

.application-description-section {
  grid-column: 4 / span 10;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 25px;
}

.Model-Requirements-Header {
  grid-column: 1 / span 16;
  justify-self: center;
  font-size: 24px;
}

.Model-Application-Header {
  font-weight: 800;
}

.Application-Basic-Information-Header {
  grid-column: 2 / span 14;
  font-size: 14px;
}

.ModelRequirementsBoxes {
  grid-template-columns: repeat(3, 100px);
  justify-items: center;
  grid-column: 1 / span 16;
  justify-self: center;
  display: grid;
  grid-auto-rows: 90px;
}

.application-description-section {
  grid-column: 2 / span 14;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.Model-Requirements-Header {
  grid-column: 1 / span 16;
  justify-self: center;
  font-size: 24px;
  font-weight: 800;
}

.Dropdown-control {
  font-size: 9px;
}

.Model-Application-Header {
  font-weight: 800;
  grid-column: 1 / span 16;
  justify-self: center;
  font-size: 24px;
}

.Application-Basic-Information-Header {
  grid-column: 2 / span 14;
  font-size: 20px;
  font-weight: 100;
}

.Application-Basic-Information-Section {
  grid-column: 2 / span 14;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.Application-Gender-Section {
  grid-column: 2 / span 14;
  display: grid;
  grid-template-columns: auto auto calc(100vw - 50px);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
}

.Application-Gender-Header {
  font-size: 20px;
  font-weight: 100;
}

.Application-Social-Media-Header {
  grid-column: 2 / span 14;
  font-size: 20px;
  font-weight: 100;
}

.Application-Social-Profiles-Section {
  grid-column: 2 / span 14;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.Application-Photos-Header {
  grid-column: 2 / span 14;
  font-size: 20px;
  font-weight: 100;
}

.Application-Photos-Section {
  grid-column: 2 / span 14;
}

.Application-Profile-Picture-Header {
  font-size: 20px;
  font-weight: 100;
  grid-column: 2 / span 14;
}

.Profile-Picture-Section {
  grid-column: 2 / span 14;
}

@media only screen and (max-width: 800px) {
  .ApplicationMainContent {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-column: 1 / span 16;
    grid-row-gap: 30px;
    grid-template-rows:
      0px max-content max-content max-content max-content max-content 20px max-content max-content max-content
      max-content max-content max-content max-content max-content max-content max-content max-content max-content 50px 10px max-content 50px 50px 175px 100px;
    /*10            11           12         13           14       15           16             17      18 */
  }
  .ApplicationLeftContent {
    display: none;
  }
  .ModelRequirementsBoxes {
    grid-template-columns: repeat(3, 100px);
    justify-items: center;
  }

  .MobileCampaignDetails {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-row: 3;
    font-size: 14px;
    grid-row-gap: 10px;
  }

  .application-description-section {
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 25px;
    letter-spacing: 0.5px;
  }

  .Model-Requirements-Header {
    justify-self: center;
    font-size: 24px;
    font-weight: 800;
  }

  .Dropdown-control {
    font-size: 9px;
  }

  .Dropdown-placeholder {
    padding: 0;
  }

  .Model-Application-Header {
    font-weight: 800;
    grid-column: 1 / span 16;
    justify-self: center;
    font-size: 24px;
  }

  .Application-Basic-Information-Header {
    grid-column: 2 / span 14;
    font-size: 20px;
    font-weight: 100;
  }

  .Application-Basic-Information-Section {
    grid-column: 2 / span 14;
  }

  .Application-Gender-Section {
    grid-column: 2 / span 14;
    display: grid;
    grid-template-columns: auto auto calc(100vw - 50px);
    grid-column-gap: 20px;
    grid-row-gap: 30px;
  }

  .Application-Gender-Header {
    font-size: 20px;
    font-weight: 100;
  }

  .Application-Social-Media-Header {
    grid-column: 2 / span 14;
    font-size: 20px;
    font-weight: 100;
  }

  .Application-Social-Profiles-Section {
    grid-column: 2 / span 14;
  }

  .Application-Photos-Header {
    grid-column: 2 / span 14;
    font-size: 20px;
    font-weight: 100;
  }

  .Application-Photos-Section {
    grid-column: 2 / span 14;
  }

  .Application-Profile-Picture-Header {
    font-size: 20px;
    font-weight: 100;
    grid-column: 2 / span 14;
  }

  .Profile-Picture-Section {
    grid-column: 2 / span 14;
  }
}
