.CampaignApplicantDetailsContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-row-gap: 20px;
  grid-template-rows:
  minmax(auto, 170px)
  40px
  40px
  20px
  20px
  max-content
  20px
  max-content
  20px
  auto
  max-content;

}

.CampaignApplicantDetailsContainerNetwork {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-row-gap: 20px;
  grid-template-rows: max-content;

}


.Basic-Information-Campaign-Applicant-Details {
  grid-column:4 / span 10;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;
}

.Profiles-Campaiagn-Applicant-Details {
  grid-column:4 / span 10;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;
}

.Photos-Campaign-Applicant-Details{
  grid-column: 4 / span 4;
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  grid-row-gap: 30px;
  grid-column-gap: 30px;
}

.ButtonContainer {
  display:grid;
  grid-template-columns: 40px max-content;
  border: 1px solid #ccc;
  background-color:rgb(255, 255, 255);
}

.ButtonIcon {
  grid-column: 1;
  align-self:center;
  justify-self:center;
}

.ButtonIcon img {
  width: 20px;
  height: 20px;
}

.ButtonText {
  grid-column: 2;
  align-self:center;
  padding-left: 60px;
}
@media only screen and (max-width: 1000px) {
  .Basic-Information-Campaign-Applicant-Details {
    grid-template-columns: repeat(1,1fr);
  }
  .Profiles-Campaiagn-Applicant-Details {
    grid-template-columns: repeat(1,1fr);
  }
  .Photos-Campaign-Applicant-Details {
    grid-template-columns: repeat(1,1fr);
  }
  .ButtonText {
    grid-column: 2;
    align-self:center;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 600px) {

  .ButtonText {
    padding-left: 20px;
  }
}
