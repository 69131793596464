.NetworkModelDetailsContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-template-rows: 0px 0px 0px 0px minmax(50px,min-content) 10px 20px 20px minmax(50px, auto) 40px 20px 20px  minmax(50px, auto) 40px 20px 20px minmax(150px, auto)
                    /* cp   gap   |    |        campaign-details     |   |                  |                               |
                                title  |                            Gap  |                  |                             Profiles
                                    arrrows                              |                  |
                                                                  Basic Info Title          |
                                                                                        Basic Info      Prof Title
                                                      5                    7                  9             11            13
                                    */
}
