

@media (max-width: 414px) {
  .Thank-You-For-Applying-Main-Heading {
    font-size: 24px;
  }

  .Thank-You-For-Applying-Sub-Heading {
    font-size: 11px;
  }
}


@media (max-width: 375px) {
  .Thank-You-For-Applying-Main-Heading {
    font-size: 18px;
  }

  .Thank-You-For-Applying-Sub-Heading {
    font-size: 10px;
  }
}

@media (max-width: 320px) {
  .Thank-You-For-Applying-Main-Heading {
    font-size: 16px;
  }

  .Thank-You-For-Applying-Sub-Heading {
    font-size: 8px;
  }
}
