@import url("https://fonts.googleapis.com/css?family=Karla");

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNovaSBold"),
    url(./fonts/FontsFree-Net-Proxima-Nova-Sbold.otf) format("truetype");
}

@font-face {
  font-family: "Trade Gothic";
  src: url(./fonts/TradeGothic/TradeGothicLTStd.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(./fonts/TradeGothic/TradeGothicLTStd-Cn18.otf) format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(./fonts/TradeGothic/TradeGothicLTStd-BdCn20.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(./fonts/TradeGothic/TradeGothicLTStd-Bd2Obl.otf) format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(./fonts/TradeGothic/TradeGothicLTStd-BoldExt.otf) format("opentype");
  font-weight: 1000;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(./fonts/TradeGothic/TradeGothicLTStd-Bold.otf) format("opentype");
  font-weight: 1000;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url(./fonts/Fakt/FaktPro-Normal.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100% !important;
  position: relative !important;
  overflow: inherit !important;

  min-height: 100% !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: Karla;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

textarea {
  resize: none;
}

.Buttonized-Tags {
  color: #39739d;
  background-color: #e1ecf4;
  border-color: #e1ecf4;
  margin: 2px 2px 2px 0;

  text-align: center;
  white-space: nowrap;
  text-decoration: none;
}
