
#NetworkUpperSection {
  grid-column: 1 / span 16;
  grid-row: 1;
  display: grid;
  grid-auto-rows: max-content;
  align-self: center;
  grid-row-gap: 10px;
}


.ModelNetworkHeader {
  grid-column: 1/span 16;
  justify-self: center;
  font-size:24px;
  font-weight:800;
  padding-top: 40px;
}

.ModelNetworkSearch {
  grid-column: 2;
}

.ModelNetworkSearch {
  justify-self:center;
  padding-top: 10px;
}

@media only screen and (max-width: 1000px) {
  #NetworkUpperSection {
    grid-column: 1 / span 16;
    grid-row: 1;
    display: grid;
    grid-auto-rows: max-content;
    align-self: center;
    grid-row-gap: 10px;
  }

  .ModelNetworkHeader {
    grid-column: 1/span 16;
    justify-self: center;
    font-size:24px;
    font-weight:800;
    padding-top: 40px;
  }


  .ModelNetworkSearch {
    grid-column: 1;
  }
}

@media only screen and (max-width: 600px)  {
    .ModelNetworkHeader {
      display: none;
    }

}





/* old css below
.NetworkGridContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 80px 40px minmax(700px, max-content);
  background-color: #F6F8FA;
  height: 100vh;
}

.NetworkTableHeaders {
  display:grid;
  grid-template-columns:repeat(6,1fr);
  align-self: center;
}

.NetworkMainTable {
  margin-top: 20px;
  background-color: #fff;
  border: .5px solid #D3D3D3;
  display:grid;
  grid-template-columns:  repeat(6,1fr);
  grid-auto-rows: max-content;
  padding: 4px 10px;
}

.NetworkCampaignCount {

}

.network-model-options-modal {
  position: absolute;
  align-items: center;
  display: none;
  background-color:#fff;
  grid-template-columns:80px;
  border: .5px solid black;
}


@media only screen and (max-width: 1000px) {
  .NetworkGridContainer div {
    font-size: 10px;
  }
  #network-profile-picture {

  }

  .network-model-options-modal {
    position: absolute;
    align-items: center;
    display: none;
    background-color:#fff;
    grid-template-columns:50px;
    border: .5px solid black;
  }


  .NetworkTableHeaders {
    margin-top: 20px;
    display:grid;
    grid-template-columns:140px 60px 60px repeat(2,1fr);
  }

  .NetworkMainTable {
    margin-top: 20px;
    background-color: #fff;
    border: .5px solid #D3D3D3;
    display:grid;
    grid-template-columns: 140px 60px 60px repeat(2,1fr);
    grid-auto-rows: max-content;
  }

  .NetworkTagsHeader {
    display: none;
  }

  .NetworkTags {
    display: none;
  }

  .NetworkCampaignCountHeader {
    justify-self: center;
  }

  .NetworkCampaignCount {
    justify-self: center;
    padding-left: 0px;
  }

  .CityHeader {
    justify-self:center;
  }

  .CityData {
    justify-self: center;
  }

  .NetworkPreviousButton{
    height: 18px;
  }

  .NetworkNextButton {
    height: 18px;
  }
}
*/
