@import url(https://fonts.googleapis.com/css?family=Karla);
@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNovaSBold"),
    url(/static/media/FontsFree-Net-Proxima-Nova-Sbold.f14eee64.otf) format("truetype");
}

@font-face {
  font-family: "Trade Gothic";
  src: url(/static/media/TradeGothicLTStd.fbb1aa81.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(/static/media/TradeGothicLTStd-Cn18.90c5331a.otf) format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(/static/media/TradeGothicLTStd-BdCn20.92d685f7.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(/static/media/TradeGothicLTStd-Bd2Obl.fb236cbc.otf) format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(/static/media/TradeGothicLTStd-BoldExt.50717d0b.otf) format("opentype");
  font-weight: 1000;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic";
  src: url(/static/media/TradeGothicLTStd-Bold.73b2bf86.otf) format("opentype");
  font-weight: 1000;
  font-style: italic;
}

@font-face {
  font-family: "Fakt";
  src: url(/static/media/FaktPro-Normal.f7da5fea.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100% !important;
  position: relative !important;
  overflow: inherit !important;

  min-height: 100% !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: Karla;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

textarea {
  resize: none;
}

.Buttonized-Tags {
  color: #39739d;
  background-color: #e1ecf4;
  border-color: #e1ecf4;
  margin: 2px 2px 2px 0;

  text-align: center;
  white-space: nowrap;
  text-decoration: none;
}

.App {
  min-height: 100vh;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.FullWidth {
  width: 100%;
}

.HomepageGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-row-gap: 72px;
  /*
      REGARDING GRID-TEMPLATE-ROWS
      200PX IS THE HEIGHT OF THE FIRST ROW  ---> NAVIGATION / HEADERS ( THIS ROW IS PROBABLY NOT NEEDED ANYMORE
        because I refactored Navigation to its own component although if I remove the first 200px I would have
        to do some refactoring and update every pages respective row. Might do it later but theirs no problem
        having that 200px their for now.
      )
      600PX IS THE HEIGHT OF THE SECOND ROW ---> PAGE 1 CONTENT
      300PX IS THE HEIGHT OF THE THIRD ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      500PX IS THE HEIGHT OF THE FOURTH ROW ---> PAGE 2 CONTENT
      300PX IS THE HEIGHT OF THE FIFTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      900PX IS THE HEIGHT OF THE SIXTH ROW ---> PAGE 3 CONTENT
      300PX IS THE HEIGHT OF THE SEVENTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      600PX IS THE HEIGHT OF THE EIGHTH ROW ---> PAGE 4 CONTENT
      250PX IS THE HEIGHT OF THE NINTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      780PX IS THE HEIGHT OF THE TENTH ROW ---> PAGE 5 CONTENT
      100PX IS THE HEIGHT OF THE ELEVENTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      600PX IS THE HEIGHT OF THE TWELTH ROW ---> PAGE 6 CONTENT
      150PX IS THE HEIGHT OF THE THIRTEENTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      400PX IS THE HEIGHT OF THE FOURTEENTHROW ---> FOOTER
   */
  width: 100%;
}

/* GRID MEDIA QUERIES */
@media only screen and (max-width: 1300px) {
  .HomepageGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    width: 100%;
  }
}

/* GRID MEDIA QUERIES */
@media only screen and (max-width: 600px) {
  .HomepageGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    width: 100%;
  }
}

/* GRID MEDIA QUERIES */

.PageOne {
  margin-top: 72px;
  grid-column: 1 / span 16;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

.ContentPageOne {
  align-self: center;
  grid-column: 2 / span 5;
  min-width: 240px;
}

.ContentPageOneHeadingInfo {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
}

.ContentPageOneSubHeadingInfo {
  margin-bottom: 15px;
}

.ContentPageOneSectionTwo {
  grid-column: 16;
}

.ContentPageOneEmailInputMobile {
  display: none;
}

.ContentPageOneEmailInput {
  grid-column: 1 / span 13;
}

.ContentPageOneEmailInput input {
  width: 100%;
  margin: 0;
  padding: 0 25px;
  padding-bottom: 5px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  font-family: Karla;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.57px;
  line-height: 14px;
  border: 1px solid #000;
  background: none;
  outline: none;
  height: 42px;
}

.ContentPageOneEmailInput button {
  visibility: hidden;
  width: 0;
  height: 0;
  border: none;
  padding: 0;
}
.ContentPageOneEmailInput label {
  display: none;
}

.ContentPageOneEmailInput input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #000 inset;
  -webkit-text-fill-color: #fff;
}

#CampaignsImage {
  width: 640px;
}

/* PAGE ONE MEDIA QUERIES */

@media only screen and (max-width: 960px) {
  .PageOne {
    grid-column: 1 / span 16;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
  }

  #CampaignsImage {
    content: url(/static/media/campaigns-mobile-desktop-view.bec46aab.jpg);
  }

  .ContentPageOneHeadingInfo {
    margin-bottom: 15px;
  }

  .ContentPageOne {
    grid-column: 4 / span 10;
    text-align: center;
    justify-self: center;
    width: auto;
  }

  .ContentPageOneSectionTwo {
    grid-column: 1 / span 16;
    display: flex;
    flex-direction: column;
  }

  .ContentPageOneEmailInput {
    justify-self: normal;
    grid-column: 2 / span 12;
  }

  .ContentPageOneEmailInputMobile {
    display: block;
    height: 44px;
    align-self: center;
    width: 300px;
    margin-right: 60px;
  }

  .ImagePageOneColumns {
    align-self: center;
  }

  #CampaignsImage {
    width: 396px;
  }

  .ContentPageOneEmailInputWeb {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  #CampaignsImage {
    width: 340px;
  }
}

@media only screen and (max-width: 550px) {
  .ContentPageOneEmailInput {
    grid-column: 2 / span 12;
    justify-self: normal;
  }
}

.PageTwo {
  grid-column: 1 / span 16;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  height: -webkit-fill-available;
}

.ImageTwo {
  grid-column: 9 / span 15;
}

.ImageTwo img {
  object-fit: cover;
  width: 100%;
}

.IconPageTwo {
  background-color: rgba(255, 255, 255, 0);
  margin: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.ContentPageTwo {
  grid-column: 2 / span 5;
  display: grid;
  align-self: flex-start;
}

.ContentPageTwoHeadingInfo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* PAGE TWO MEDIA QUERIES */
@media only screen and (min-width: 1000px) {
  .ImageTwo {
    grid-column: 16;
  }
  #CreateCampaignImage {
    width: 640px;
  }
}

@media only screen and (max-width: 750px) {
  .ImageTwo {
    object-fit: cover;
    width: 100%;
    grid-column: 1 / span 16;
    margin-top: 55px;
  }

  .ContentPageTwo {
    grid-column: 4 / span 10;
  }

  .IconPageTwo {
    background-color: rgba(255, 255, 255, 0);
    margin: 0;
    padding: 5px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  #CreateCampaignImage {
    content: url(/static/media/mobile-desktop-create-campaign.82debbfc.jpg);
  }
}

.PageThree {
  grid-column: 1 / span 16;
  background-color: rgb(232, 253, 255);
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  /*
    Since we had to change the background of this entire sub page I need to add a grid within a grid to make the layout with the background
  */
  align-items: center;
  height: -webkit-fill-available;
  min-height: 800px;
}

.ImagePageThree {
  object-fit: cover;
  width: 100%;
  grid-column: 2 / span 4;
}

.ContentPageThree {
  grid-column: 10 / span 6;
}

.InnerContentPageThree {
  display: grid;
  justify-items: flex-end;
  text-align: right;
}

.AnalyticsIcon {
  justify-self: flex-end;
  margin-bottom: 10px;
}

.ContentPageThreeHeadingInfo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* PAGE THREE MEDIA QUERIES */
@media only screen and (max-width: 750px) {
  .ContentPageThree {
    grid-column: 1 / span 16;
    grid-row: 1;
  }

  .ImagePageThree {
    object-fit: cover;
    width: 100%;
    grid-column: 1 / span 16;
    text-align: center;
  }

  .ImagePageThree img {
    width: 300px;
  }

  .InnerContentPageThree {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    text-align: left;
  }

  .AnalyticsIcon {
    grid-row: 1;
    justify-self: flex-start;
    grid-column: 4 / span 10;
    margin-bottom: 10px;
  }

  .ContentPageThreeHeadingInfo {
    grid-row: 2;
    font-size: 28px;
    font-style: bold;
    margin-bottom: 15px;
    grid-column: 4 / span 12;
    margin-top: 5px;
  }

  .ContentPageThreeSubheadingInfo {
    grid-row: 3;
    grid-column: 4 / span 10;
    margin-top: 15px;
  }

  #AnalyticsImage {
    content: url(/static/media/analytics-mobile.cae53fad.jpg);
  }
}

.PageFour {
  grid-column: 1 / span 16;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  height: -webkit-fill-available;
  min-height: 800px;
}

.PageFourContent {
  grid-column: 2 / span 6;
  align-self: center;
  margin-bottom: 200px;
}

.ContentPageFourHeadingInfo {
  font-size: 28px;
  font-style: bold;
  margin-bottom: 15px;
}

.NetworkIcon {
  margin-bottom: 10px;
}

.PageFourImage {
  grid-column: 8 / span 12;
  align-self: center;
}

.PageFourImage img {
  height: 100%;
  width: 100%;
}

/* Page Four Media Queries */
@media only screen and (min-width: 1000px) {
  .PageFourImage {
    grid-column: 16;
  }

  .PageFourImage img {
    width: 640px;
  }
}

@media only screen and (max-width: 750px) {
  .PageFourContent {
    grid-column: 4 / span 10;
    align-self: center;
    margin-bottom: 35px;
  }

  .ContentPageFourHeadingInfo {
    font-size: 28px;
    font-style: bold;
    margin-bottom: 15px;
  }

  .NetworkIcon {
    margin-bottom: 10px;
  }

  .PageFourImage {
    grid-column: 1 / span 16;
  }

  .PageFourImage img {
    height: 100%;
    width: 100%;
    content: url(/static/media/network-image-mobile.fb0164d3.jpg);
  }
}

.PageFive {
  grid-column: 1 / span 16;
  /* Neeed Another Grid Inside To Make The Layout */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 100px 0px;
}

.PageFiveLeftCollage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 50px;
}

.PageFiveLeftCollage img {
  height: 144px;
  width: 90px;

}

.CollageLeftOne {
  grid-column: 1;
  grid-row: 2;
}

.CollageLeftTwo {
  grid-column: 1;
  grid-row: 5;
}

.CollageLeftThree {
  grid-column: 1;
  grid-row: 8;
}

.CollageLeftFour {
  grid-column: 1;
  grid-row: 11;
}

.CollageLeftFive {
  grid-column: 2;
  grid-row: 1;
}

.CollageLeftSix {
  grid-column: 2;
  grid-row: 4;
}

.CollageLeftSeven {
  grid-column: 2;
  grid-row: 7;
}

.CollageLeftEight {
  grid-column: 2;
  grid-row: 10;
}

.CollageLeftNine {
  grid-column: 3;
  grid-row: 3;
}

.CollageLeftTen {
  grid-column: 3;
  grid-row: 6;
}

.CollageLeftEleven {
  grid-column: 3;
  grid-row: 9;
}

.CollageLeftTwelve {
  grid-column: 3;
  grid-row: 12;
}

.PageFiveRightCollage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 50px;
  grid-column-gap: 12px;
}

.PageFiveRightCollage img {
  height: 144px;
  width: 90px;
}

.CollageRightOne {
  grid-column: 1;
  grid-row: 5;
}

.CollageRightTwo {
  grid-column: 1;
  grid-row: 8;
}

.CollageRightThree {
  grid-column: 1;
  grid-row: 11;
}

.CollageRightFour {
  grid-column: 2;
  grid-row: 3;
}

.CollageRightFive {
  grid-column: 2;
  grid-row: 6;
}

.CollageRightSix {
  grid-column: 2;
  grid-row: 9;
}

.CollageRightSeven {
  grid-column: 2;
  grid-row: 12;
}

.CollageRightEight {
  grid-column: 3;
  grid-row: 2;
}

.CollageRightNine {
  grid-column: 3;
  grid-row: 5;
}

.CollageRightTen {
  grid-column: 3;
  grid-row: 8;
}

.CollageRightEleven {
  grid-column: 3;
  grid-row: 11;
}

.CollageRightTwelve {
  grid-column: 3;
  grid-row: 12;
}

.PageFiveMission {
  align-self: center;
  margin-bottom: 40px;
  font-size: 20px;
}

.OurMissionTextHeading {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1100px) {
  .PageFiveMission {
    align-self: center;
    font-size: 20px;
  }
}

/* Page Five Media Queries */
@media only screen and (max-width: 900px) {
  .PageFive {
    grid-column: 1 / span 16;
    /* Neeed Another Grid Inside To Make The Layout */
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    background-color: rgb(32, 51, 65);
  }

  .PageFiveLeftCollage {
    display: none;
  }

  .PageFiveRightCollage {
    display: none;
  }

  .PageFiveRightCollage img {
    height: 144px;
    width: 90px;
  }

  .PageFiveMission {
    grid-column: 4 / span 10;
    align-self: center;
    margin-bottom: 40px;
    color: white;
  }

  .OurMissionTextHeading {
    margin-bottom: 20px;
  }
}

.PageSix {
  grid-column: 1 / span 16;
  justify-self: center;
}

.PageSixContent {
  text-align: center;
}

.PricingHeading {
  font-size: 32px;
  font-style: bold;
  margin-bottom: 15px;
}

.PricingSubheader {
}

.PricingModal {
  margin-top: 40px;
}

/* Page six Media Queries */
@media only screen and (max-width: 750px) {
  .PageSix {
    margin: 0 12px;
  }
  #PricingModal {
    height: 100%;
    width: 100%;
  }
}

.Footer {
  grid-column: 1 / span 16;
  background-color: rgb(210, 227, 230);
  /* Using A Grid To Make The Layout for the Four Parts Of The Footer */
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

.FooterTrademarks {
  grid-row: 2;
  grid-column: 2 / span 4;
}

.FooterTradeMarkIcon {
  margin-bottom: 10px;
}

.FooterTrademarkHeading {
  letter-spacing: 0.57px;
  line-height: 24px;
  font-size: 24px;
  font-style: bold;
  margin-bottom: 15px;
}

.FooterAbout {
  grid-row: 3;
  grid-column: 7 / span 2;
}

.FooterAboutHeading {
  font-weight: bold;
}

.FooterAbout div {
  margin-bottom: 15px;
}

.FooterLegal {
  grid-row: 3;
  grid-column: 10 / span 2;
}

.FooterLegalHeading {
  font-weight: bold;
}

.FooterLegal div {
  margin-bottom: 15px;
}

.FooterGetInTouch {
  grid-row: 3;
  grid-column: 13 / span 2;
}

.FooterGetInTouchHeading {
  font-weight: bold;
}

.FooterGetInTouch div {
  margin-bottom: 15px;
}

.NavbarContainer {
  margin-top: 30px;
  display:grid;
  grid-template-columns: repeat(16, 1fr);
  align-items: center;
}

.NavbarContainer div ,a, a:visited, a:hover, a:focus {
  text-decoration: none;
  color:black;
  text-transform: uppercase;
}

.Logo {
  grid-column: 2 / span 2;
  grid-row: 1;
  font-weight: bold;
  white-space: nowrap;
}

.LinkToCampaigns {
  grid-column: 8 / span 2;
}

.About {
  grid-column: 5 / span 1;
}

.NavCampaigns {
  grid-column: 9 / span 1;
}

.Features {
  grid-column: 11 / span 1;
  cursor: pointer;
}

.Pricing {
  grid-column: 13 / span 1;
  cursor: pointer;
}

.ComingSoon {
  grid-column: 15 / span 1;
}

.bm-burger-button {
  display:none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  display:none;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  display:none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display:none;
}

/* Color/shape of close button cross */
.bm-cross {
  display:none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  display:none;
}

/* General sidebar styles */
.bm-menu {
  display:none;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  display:none;
}

/* Wrapper for item list */
.bm-item-list {
  display:none;
}

/* Individual item */
.bm-item {
    display:none;
}

/* Styling of overlay */
.bm-overlay {
  display:none;
}


/*NAVIGATION MEDIA QUERIES */

@media only screen and (max-width: 1100px)  {
    .ComingSoon {
      grid-column: 15/ span 2;
    }
}

@media only screen and (max-width: 600px)  {
  .NavbarContainer {
    display:grid;
    grid-template-columns: repeat(16, 1fr);
    font-size: 14px;
  }

  .Logo {
    grid-column: 2 / span 2;
    grid-row: 1;
  }

  .About {
    grid-column: 8 / span 1;
  }

  .NavCampaigns {
    grid-column: 10 / span 4;
    grid-row: 3;
    margin-top: 24px;
  }

  .Features {
    grid-column: 8 / span 1;
    grid-row: 3;
    margin-top: 24px;
  }

  .Pricing {
    grid-column:  6 / span 1;
    grid-row: 3;
    margin-top: 24px;
  }

  .ComingSoon {
    grid-column: 11 / span 4;
  }

 }


.AuthenticatedNavbarContainer {
  background-color: #24292E;
  display:grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 70px;
  align-items: center;
}

.AuthenticatedNavbarContainer div a{
  color: white;
}

.AuthenticatedNavbarContainer .Logo {
  grid-column: 2 / span 2;
  font-weight: bold;
  white-space: nowrap;
}

.CampaignsLink {
  grid-column: 7 / span 1;
  grid-row: 1;
}

.NetworkLink {
  grid-column: 9 / span 1;
  grid-row: 1;
}

.ProfileLink {
  grid-column: 10 / span 2;
}

.NotificationsLink {
  grid-column: 14 / span 1;
}

.NavPhoto {
  grid-column: 15 / span 1;
}

.NavPhoto img{
  border-radius: 50%;
  object-fit: cover;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
}

.Logout {
  grid-column: 16;
}

.Notifications-Modal-Container {
  display: none;
  background-color: #fff;
  grid-template-columns: repeat(1,-webkit-max-content);
  grid-template-columns: repeat(1,max-content);
  border: 1px solid black;
  position: absolute;

  z-index:1000;
  grid-auto-rows: auto;
  grid-row-gap: 5px;
  top: 60px;
  right: 30px;
}

.RunwaySupplyMobileHeaderLink {
  display:none;
}


@media only screen and (max-width: 600px)  {
  #Selected-Link {
    display:none;
  }
    .AuthenticatedNavbarContainer {
      background-color: #24292E;
      display:grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 70px;
      align-items: center;
    }

    .AuthenticatedNavbarContainer div a{
      color: white;
    }

    .AuthenticatedNavbarContainer .Logo {
      grid-column: 2 / span 2;
      font-weight: bold;
      white-space: nowrap;
      display: none;
    }

    .CampaignsLink {
      grid-column: 1;
      justify-self: center;
      display: none;
    }

    .NetworkLink {
      grid-column: 2;
      justify-self: flex-end;
      display: none;
    }

    .ProfileLink {
      display: none;
    }

    .NotificationsLink {
      grid-row: 1;
      grid-column: 1 / span 2;
      justify-self:flex-end;
      padding-right: 30px;
      }

    .RunwaySupplyMobileHeaderLink {
      grid-row:1;
      display: block;
      grid-column: 1 / span 2;
      justify-self:center;
      font-size: 18px;
      color:white;
      letter-spacing: 1px;
    }

    .NavPhoto {
      grid-column: 4;
      display:none;

    }

    .NavPhoto img{
      border-radius: 50%;
      object-fit: cover;
      width: 40px;
      height: 40px;
    }

    .BrandLogoMobile {
      border-radius: 50%;
      object-fit: cover;
      width:80px;

      height: 80px;

    }

    .Logout {
      grid-column: 16;
    }

    /* Position and sizing of burger button */
.bm-burger-button {
    display: block;
    position: absolute;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 24px;
    color:white;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    display: block;
    background-color: #c3c3c3;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    display: block;

}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    display: block;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  display: block;
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  display: block;
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  display: block;
  background: #24292E;;
  font-size: 1.15em;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  display: block;
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  display: block;
  color: #b8b7ad;

}

/* Individual item */
.bm-item {
  display: block;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.Hamburger-Navigation-Links {
  display: grid;
  grid-template-rows: 120px 70px 70px 70px 70px 70px;
  padding-top: 40px;

}

.Hamburger-Navigation-Links-Border-Item-One {
  padding-left: 10px;

}

.Hamburger-Navigation-Links-Border-Item-General {
  padding-left: 10px;
}

.Hamburger-Navigation-Links-Border-Item-Last {
  padding-left: 10px;
}

  .Notifications-Modal-Container {
    display: none;
    background-color: #fff;
    grid-template-columns: repeat(1,-webkit-max-content);
    grid-template-columns: repeat(1,max-content);
    border: 1px solid black;
    position: absolute;
    justify-self:center;
    z-index:1000;
    grid-auto-rows: auto;
    grid-row-gap: 5px;
    top: 70px;
  }

 }

.ModalGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 80px 80px 80px 80px 80px 80px 50px 30px 80px;
  text-transform: uppercase;
  width: 600px;
}

.DialogTitle {
  text-align: center;
  grid-row: 1;
  grid-column: 1 / span 16;
  align-self: center;
  font-weight: 800;
  font-size: 24px;
}

.RegistrationRunwaySupplyIcon {
  grid-row: 1;
  grid-column: 4 / span 4;
  align-self: center
}

.RunwaySupplyIconWidth {
  width: 44px;
}

.DialogContent {
  grid-row: 2 / span 6;
  grid-column: 4 / span 10;
}

.DialogContent input {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  padding: 7px;
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: calc(100% - 14px);
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}

/* This Container Holds Our Input and Icon Its on A div */
.DialogContentInputContainer {
  display: grid;
  grid-column: 1 / span 16;
  grid-template-columns: 60px 4fr;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  height:45px;
}

.DialogContentInputContainer:focus-within input {
  grid-column: 1/4;
  padding-left: 55px;
}

.DialogContentInputContainer:focus-within input::-webkit-input-placeholder {
  color: transparent;
}

.DialogContentInputContainer:focus-within input::-moz-placeholder {
  color: transparent;
}

.DialogContentInputContainer:focus-within input::-ms-input-placeholder {
  color: transparent;
}

.DialogContentInputContainer:focus-within input::placeholder {
  color: transparent;
}

.DialogContentInputContainer img {
  padding: 0.5rem;
  grid-row: 1;
  grid-column: 1/2;
  z-index: 2;
}

.DialogContentInputContainer input {
  border: 0;
  padding: 0.5rem;
  grid-column: 2/4;
  grid-row: 1;
  outline: none;
  font-size: 16px;
}

.DialogContent ::-webkit-input-placeholder {
  padding-right: 60px;
  text-align: center;
}

.DialogContent :-moz-placeholder {
  text-align: center;
}

.DialogContent ::-moz-placeholder {
  text-align: center;
}

.DialogContent :-ms-input-placeholder {
  text-align: center;
}

.CardContainer {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  padding: 14px;
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-top: 20px;
  max-width: -webkit-fill-available;
}

.DialogActions {
  grid-row: 7;
  grid-column: 4 / span 10;
  position:relative;
  height:47px;
}

.DialogActions button {
  text-transform: uppercase;
  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.DialogContentTextLogin {
  grid-row: 8;
  grid-column: 1 / span 16;
  text-align: center;
  font-size: 14px;
  color: #afafaf;
}

.DialogContentTextLogin span {
  text-decoration: underline;
}

.DialogContextTextSignup {
  grid-row: 9;
  grid-column: 4 / span 10;
  font-size: 10px;
  text-align: center;
  color: #afafaf;
}

.DialogContextTextSignup span {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .ModalGrid {
    width: auto;
  }

  .DialogTitle {
    text-align: center;
    grid-row: 1 / span 2;
    grid-column: 1 / span 16;
    align-self: center;
  }

  .RunwaySupplyIconWidth {
    width: 44px;
  }

  .RegistrationRunwaySupplyIcon {
    grid-row: 1 / span 2;
    grid-column: 1 / span 3;
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: 20px;
  }

  .DialogTitle {
    text-align: center;
    grid-row: 1;
    grid-column: 4 / span 10;
  }

  .DialogContent {
    grid-row: 2 / span 5;
    grid-column: 1 / span 16;
  }

  .CardContainer {
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding: 7px;
    padding-top: 19px;
    padding-bottom: 19px;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.23);
    max-width: -webkit-fill-available;
  }

  .DialogActions {
    grid-row: 7;
    grid-column: 1 / span 16;
  }

  .DialogActions button {
    background: #ff4742;
    font-weight: 800;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff4742;
    display: inline-block;
    padding: 14px;
    display: block;
    border-radius: 5px;
    width: 100%;
  }

  .DialogContentTextLogin {
    font-size: 12px;
  }

  /*
     These weird classnames are the classnames of the modal so I make the modal take the full height
     and width when we are in mobile
   */
  .styles_overlay__CLSq- {
    width: 100%;
    padding: 0;
  }

  .styles_modal__gNwvD {
    height: 100%;
  }
}

/* LOGIN */
.LoginModalGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content, -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content max-content, max-content max-content;
  grid-row-gap: 20px;
  text-transform: uppercase;
  width: 600px;
  height: 300px;
}

.LoginDialogTitle {
  text-align: center;
  grid-row: 1;
  grid-column: 1 / span 16;
  align-self: center;
  font-weight: 800;
  font-size: 24px;
}

.LoginRunwaySupplyIcon {
  grid-row: 1;
  grid-column: 4;
  align-self: center;
}

.LoginRunwaySupplyIconWidth {
  width: 48px;
}

.LoginDialogContent {
  grid-row: 2 / span 2;
  grid-column: 4 / span 10;
}

.LoginDialogActions {
  grid-row: 3;
  grid-column: 4 / span 10;
}

.LoginDialogActions button {
  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
}

.RedButton {
  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
}

.MiniSubHeaderForModal {
  grid-column: 1 / span 16;
  text-align: center;
  font-size: 14px;
  color: #afafaf;
}

.MiniSubHeaderForModal span {
  text-decoration: underline;
}

.DialogContentTextForgotPassword {
  grid-row: 4;
  grid-column: 1 / span 16;
  text-align: center;
  font-size: 14px;
  color: #afafaf;
  align-self: center;
  padding-top: 35px;
}

.DialogContentTextForgotPassword span {
  text-decoration: underline;
}

.LoginDialogContent {
  grid-row: 2 / span 6;
  grid-column: 4 / span 10;
}

.LoginInputColumnHandler {
  grid-column: 4 / span 10;
}
/* login media queries */

@media only screen and (max-width: 600px) {
  .LoginModalGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: auto auto auto auto auto;
    text-transform: uppercase;
    width: calc(100vw - 40px);
    height: 300px;
    grid-row-gap: 10px;
  }

  .LoginInputColumnHandler{
    grid-column:1 / span 16;
  }

  .LoginRunwaySupplyIconWidth {
    width: 44px;
  }

  .LoginRunwaySupplyIcon {
    grid-row: 1;
    grid-column: 1 / span 3;
    justify-self: flex-star;
    align-self: flex-start;
    margin-top: 20px;
  }

  .LoginDialogTitle {
    text-align: center;
  }

  .LoginDialogContent {
    grid-row: 2;
    grid-column: 1 / span 16;
  }

  .LoginDialogActions {
    grid-column: 1 / span 16;
  }

  .LoginDialogActions button {
    background: #ff4742;
    font-weight: 800;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff4742;
    display: inline-block;
    padding: 14px;
    display: block;
    border-radius: 5px;
    width: 100%;
  }

  .DialogContentTextForgotPassword {
    font-size: 12px;
  }
}

/* forgotpassword modal styles */

.ForgotPasswordModalGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 80px 80px 80px 80px 80px 80px 80px 80px 80px;
  text-transform: uppercase;
  width: 600px;
  height: 250px;
}

.ForgotPasswordDialogTitle {
  text-align: center;
  grid-row: 1;
  grid-column: 1 / span 16;
  align-self: center;
  font-weight: 800;
  font-size: 24px;
}

.ForgotPasswordRunwaySupplyIcon {
  grid-row: 1;
  grid-column: 4 / span 4;
  align-self: center;
}

.ForgotPasswordRunwaySupplyIconWidth {
  width: 48px;
}

.ForgotPasswordDialogContent {
  grid-row: 2;
  grid-column: 4 / span 10;
}

.ForgotPasswordDialogActions {
  grid-row: 3;
  grid-column: 4 / span 10;
}

.ForgotPasswordDialogActions button {
  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
}

/* forgot password media  queries */

@media only screen and (max-width: 600px) {
  .ForgotPasswordModalGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 80px 80px 80px 80px 80px 80px 80px 80px 80px;
    text-transform: uppercase;
    width: calc(100vw - 40px);
    height: 300px;
  }

  .ForgotPasswordRunwaySupplyIconWidth {
    width: 44px;
  }

  .ForgotPasswordRunwaySupplyIcon {
    grid-row: 1;
    grid-column: 1 / span 3;
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: 20px;
  }

  .ForgotPasswordDialogTitle {
    text-align: center;
  }

  .ForgotPasswordDialogContent {
    grid-row: 2;
    grid-column: 1 / span 16;
  }

  .ForgotPasswordDialogActions {
    grid-column: 1 / span 16;
  }

  .ForgotPasswordDialogActions button {
    background: #ff4742;
    font-weight: 800;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff4742;
    display: inline-block;
    padding: 14px;
    display: block;
    border-radius: 5px;
    width: 100%;
  }
}

.CampaignsContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #f6f8fa;
  grid-template-rows: 20px 90px -webkit-max-content;
  grid-template-rows: 20px 90px max-content;
  min-height: 100vh;
  padding: 0px 0px 100px;
}

.CreateCampaign {
  grid-row: 2;
  grid-column: 13;
  width: 200px;
  background: #0097ff;
  overflow: hidden;
  height: 50px;
  display: grid;
  grid-template-columns: -webkit-min-content -webkit-max-content;
  grid-template-columns: min-content max-content;
  grid-column-gap: 12px;
  padding-left: 20px;
  align-items: center;

  color: white;
}

.CreateCampaign button {
  background: #0097ff;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;

  width: 100%;
}

.CampaignsThreeColumns {
  grid-row: 3;
  grid-column: 3 / span 12;
  display: grid;
  grid-template-columns: repeat(3, minmax(350px, 1fr));
  grid-gap: 50px 50px;
}

.CampaignBox {
  height: 350px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  grid-template-rows: 100px 200px 50px;
}

.CampaignStatistics {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 100px 100px;
}

.CampaignStatisticsViews {
  grid-row: 1;
  grid-column: 1 / span 8;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  align-items: center;
  border-left: none;
}

.CampaignStatisticsApplicants {
  grid-row: 1;
  grid-column: 9 / span 8;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  align-items: center;
  border-right: none;
}

.CampaignStatisticsShare {
  grid-row: 2;
  grid-column: 1 / span 8;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-left: none;
}

.CampaignStatisticsShareLink {
  overflow: hidden;
}

.CampaignStatisticsTextAreaShareLinkTextArea {
  height: 30px;
  font: normal 14px verdana;

  border: solid 0.1px #ddd;
  font-size: 12px;
  white-space: nowrap;
  background-color: #e6e6e6;
  overflow: hidden;
}

.CampaignStatiscsHireStatus {
  grid-row: 2;
  grid-column: 9 / span 8;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  display: grid;
  align-items: center;
  border-right: none;
}

@media only screen and (max-width: 1100px) {
  .CampaignsThreeColumns {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-gap: 50px 50px;
  }
  .CreateCampaign {
    grid-row: 2;
    grid-column: 13;
    overflow: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .CampaignsThreeColumns {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 50px 50px;
  }
  .CreateCampaign {
    grid-row: 2;
    grid-column: 13;
    overflow: hidden;
  }
}

.ViewApplicantsContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-template-rows: 200px 40px auto minmax(50px,-webkit-min-content) minmax(50px, auto) auto 20px 40px 450px;
  grid-template-rows: 200px 40px auto minmax(50px,min-content) minmax(50px, auto) auto 20px 40px 450px;
}

.Campaign-Applicants-Campaign-Details {
  display: grid;
  grid-column: 2 / span 14;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
}

.Campaign-Applicants-Hire-Limit {
  display: grid;
  grid-template-columns: 30px auto;
}

.Campaign-Applicants-Deadline {
  display: grid;
  grid-template-columns: 30px auto;
}

.Campaign-Applicants-Table-Headers {
  grid-column:2 / span 14;
  grid-row:5;
  display:grid;
  grid-template-columns: 2fr repeat(6, 1fr);
}

.Campaign-Applicants-Table {
  grid-column:1 / span 16;
  grid-row:6;
  display:grid;
  grid-template-columns:30px 2fr repeat(6, 1fr);
  background-color: #fff;
  margin-bottom: 10px;
}

.Campaign-Applicants-Models-Container {
   grid-row: 6;
   grid-column:2 / span 14;
 }

.Campaign-Applicants-Email {

}

.Campaign-Applicants-Full-Name{
  grid-column: 2;
  grid-row: 1;
  align-self: flex-end;
  white-space:nowrap;
}

.Campaign-Applicant-Social-Icon {
  width: 18px;
  height: 15px;
}

div[target=Applicants-View-Header] {
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 800;
}

.NextAndPreviousSection {
  display:grid;
  grid-template-columns: 10px 20px 10px;
  grid-row: 2;
  justify-self: flex-end;
  margin-right:12px;
}

.Campaign-Applicants-Modal-Styles{
  align-items:center;
  display: none;
  position:absolute;
  background-color:#fff;
  grid-template-columns:100px;
  border: .5px solid black;
}

.Campaign-Applicants-Model-Details-Option {
  padding-bottom: 7px;
  padding-left: 5px;
  text-transform:capitalize;
}

.Campaign-Applicants-Model-Decline-Option{
  padding-left: 5px;
  color: red;
}

.Campaign-Applicants-Socials-Section {
   align-self:  center;
   display: grid;
  grid-column-gap: 5px;
  grid-template-columns:repeat(4, 1fr);
}

.Campaign-Applicants-Campaign-Details-Item {
  display: grid;
  grid-template-columns: 30px 1fr;
}

.Campaign-Applicants-Gender {
  padding-left: 10px;
}
@media only screen and (min-width: 1600px) {
  .Campaign-Applicants-Campaign-Details {

  }

}

@media only screen and (max-width: 1000px) {

.Campaign-Applicants-Campaign-Details  {
    grid-column: 1 / span 16;
  }
  .Campaign-Applicants-Table-Headers {
    grid-column: 1 / span 16;
  }
  .Campaign-Applicants-Models-Container {
    grid-column: 1 / span 16;
  }
}

@media only screen and (max-width: 450px) {
  .Campaign-Applicants-Campaign-Details {
    display: grid;
    grid-template-columns: repeat(3,minmax(-webkit-max-content,1fr));
    grid-template-columns: repeat(3,minmax(max-content,1fr));
    grid-column: 1 / span 16;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-items: center;
    font-size: 12px;
    padding-top: 12px;
  }

  .Campaign-Applicants-Campaign-Details-Item {
    display: grid;
    grid-template-columns: 30px 1fr;
  }
  .Campaign-Applicants-Campaign-Details-Item.Four {
    display: none;
  }
 .Campaign-Applicants-Campaign-Details-Item.Five {
     display: none;
   }
  /* So the details can actually be centered must define an equal width on each div */
  .Campaign-Applicants-Hire-Limit {
    display: none;
  }

  .Campaign-Applicants-Deadline {
    display: none;
  }
  .Campaign-Applicants-Table-Headers {
    grid-template-columns: 70px 130px 70px 50px 80px;
    grid-column:1 / span 16;
    font-size: 10px;
  }

  .Campaign-Applicants-Table {
    grid-template-columns: 30px 170px 70px 25px 80px;
    font-size: 10px;
  }

  .Campaign-Applicants-Date-Applied-Header {
    display: none;
  }

  .Campaign-Applicants-Date-Applied {
    display: none;
  }

  .Campaign-Applicants-Table-City {

  }

  .Campaign-Applicants-Gender {
    text-align: center;
  }

  .Campaign-Applicants-Table-Campaign-Count {
    justify-self:center;
    display:none;
  }

  .Campaign-Applicants-Socials-Header {
    display: none;
  }

  .Campaign-Applicants-Socials-Section {
    display: none;
  }

  .Campaign-Applicants-Models-Container {
    grid-column: 1 / span 16;
  }

  div[target=Applicants-View-Header] {
    font-size: 24px !important;
  }
  .Campaign-Applicants-Model-NEP {

  }
  .Campaign-Applicants-Email {
    display: none;
  }

  .Campaign-Applicants-Full-Name{
    grid-column: 2;
    grid-row: 1 / span 2;
    align-self: center;
    white-space:nowrap;
  }

  .Campaign-Applicant-Social-Icon {
    width: 12px;
    height: 12px;
  }

  .Campaign-Applicants-Options-Section {
    grid-column: 5;
    grid-row: 1
  }

  .NextAndPreviousSection {
    grid-column: 1;
    justify-self: flex-start;
    margin: 0;
  }

  .Campaign-Applicants-Modal-Styles{
    align-items:center;
    display: none;
    position:absolute;
    background-color:#fff;
    grid-template-columns:40px;
    font-size: 10px;
    font-weight: 800;
    text-transform: capitalize;
    border: .5px solid black;

  }

  .Campaign-Applicants-Model-Details-Option {
    padding: 0px;
    padding-top: 10px;
    text-align: center;
    text-transform:capitalize;
  }
  .Campaign-Applicants-Model-Decline-Option{
    padding: 0px;
    padding-top: 10px;
    text-align: center;
    padding-bottom: 10px;
  }

  .Campaign-Applicants-PreviousButtonSize {
    height: 18px;
  }
  .CampaignApplicants-NextButtonSize {
        height: 18px;
  }
}


@media only screen and (max-width: 320px) {
  .Campaign-Applicants-Table-Headers {
    grid-template-columns: 70px 80px 70px 50px 80px;
    grid-column:1 / span 16;
    font-size: 10px;
  }

  .Campaign-Applicants-Table {
    grid-template-columns: 30px 120px 70px 25px 80px;
    font-size: 10px;
  }
  .Campaign-Applicants-Full-Name{
    grid-column: 2;
    grid-row: 1 / span 2;
    align-self: center;
    white-space:pre-wrap;
  }
}

.CampaignApplicantDetailsContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-row-gap: 20px;
  grid-template-rows:
  minmax(auto, 170px)
  40px
  40px
  20px
  20px
  -webkit-max-content
  20px
  -webkit-max-content
  20px
  auto
  -webkit-max-content;
  grid-template-rows:
  minmax(auto, 170px)
  40px
  40px
  20px
  20px
  max-content
  20px
  max-content
  20px
  auto
  max-content;

}

.CampaignApplicantDetailsContainerNetwork {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-row-gap: 20px;
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;

}


.Basic-Information-Campaign-Applicant-Details {
  grid-column:4 / span 10;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;
}

.Profiles-Campaiagn-Applicant-Details {
  grid-column:4 / span 10;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;
}

.Photos-Campaign-Applicant-Details{
  grid-column: 4 / span 4;
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  grid-row-gap: 30px;
  grid-column-gap: 30px;
}

.ButtonContainer {
  display:grid;
  grid-template-columns: 40px -webkit-max-content;
  grid-template-columns: 40px max-content;
  border: 1px solid #ccc;
  background-color:rgb(255, 255, 255);
}

.ButtonIcon {
  grid-column: 1;
  align-self:center;
  justify-self:center;
}

.ButtonIcon img {
  width: 20px;
  height: 20px;
}

.ButtonText {
  grid-column: 2;
  align-self:center;
  padding-left: 60px;
}
@media only screen and (max-width: 1000px) {
  .Basic-Information-Campaign-Applicant-Details {
    grid-template-columns: repeat(1,1fr);
  }
  .Profiles-Campaiagn-Applicant-Details {
    grid-template-columns: repeat(1,1fr);
  }
  .Photos-Campaign-Applicant-Details {
    grid-template-columns: repeat(1,1fr);
  }
  .ButtonText {
    grid-column: 2;
    align-self:center;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 600px) {

  .ButtonText {
    padding-left: 20px;
  }
}

.ViewApplicantsContainerNetwork {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-template-rows: minmax(50px,-webkit-min-content) minmax(50px, auto) auto 20px 40px 450px;
  grid-template-rows: minmax(50px,min-content) minmax(50px, auto) auto 20px 40px 450px;
}

.lds-ripple {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: fixed;
  z-index: 999;
  overflow: visible;
}

.lds-rippe .relative {
  position: relative;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fcf;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
}

/* slider */
.rc-slider-dot {
  background-color: black !important;
  display: none !important;
}
.rc-slider-handle {
  background-color: black !important;
  border: solid 2px black !important;
}
.rc-slider-track {
  background-color: black !important;
}

.rc-slider-dot-active {
  border: solid 2px black !important;
  display: block !important;
}

/* Page One Create Campaign */
.CreateCampaignsPageOneGridContainer {
}

.CreateCampaignsThumbnail {
  grid-row: 1;
  grid-column: 4 / span 13;
  overflow: hidden;
  width: 100%;
}

.CreateCampaignsChoosePhotoContainer {
  grid-column: 14 / span 2;
  grid-row: 1;
  z-index: 100;
  margin-top: 80;
  height: 32px;
}

.CreateCampaignsLeftSection {
  grid-row: 1 / span 2;
  grid-column: 1 / span 3;
  max-width: 100%;
  background-color: #24292e;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 10px 30px 20px 40px 20px 40px 45px 40px 60px 100px 10px 10px 10px;
  /* 1    2    3    4    5    6    7    8      9     10 */
  min-height: 100vh;
}

.CreateCampaignsMainSection {
  grid-row: 2;
  grid-column: 4 / span 13;
  background-color: #f6f8fa;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 50px 75px 20px 200px 50px 75px 20px 75px 5px 20px 70px 30px;
  /* 1   2    3     4    5    6    7     8    9   10   11   12 */
  min-height: calc(100vh - 170px);
}

.CreateCampaignTitleInput {
  display: grid;
  grid-template-columns: 1fr;
  grid-column: 1 / span 16;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  height: 50px;
  background: #fff;
  z-index: 2;
}

.CreateCampaignTitleInput input {
  width: 100%;
  padding: 7px 10px 11px 13px;
  display: block;
  border: none;
  font-size: 16px;
  box-sizing: border-box;
}

.CreateCampaignInputIconContainer {
  display: grid;
  grid-column: 1 / span 16;
  grid-template-columns: 60px 4fr;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  min-width: 250px;
}

.CreateCampaignInputIconContainer:focus-within input {
  grid-column: 1/4;
  padding-left: 55px;
}

.CreateCampaignInputIconContainer:focus-within input::-webkit-input-placeholder {
  color: transparent;
}

.CreateCampaignInputIconContainer:focus-within input::-moz-placeholder {
  color: transparent;
}

.CreateCampaignInputIconContainer:focus-within input::-ms-input-placeholder {
  color: transparent;
}

.CreateCampaignInputIconContainer:focus-within input::placeholder {
  color: transparent;
}

.CreateCampaignInputIconContainer img {
  padding: 0.5rem;
  grid-row: 1;
  grid-column: 1/2;
  z-index: 2;
}

.CreateCampaignInputIconContainer input {
  border: 0;
  padding: 0.5rem;
  grid-column: 2/4;
  grid-row: 1;
  outline: none;
}
.react-datepicker__input-container {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  padding-top: 0;
}

.react-datepicker__input-container:focus-within input {
  padding: 8px;
  padding-top: 0;
}

.CoverPhotoLabel {
  cursor: pointer;
}
/* Page Two Model Specs */
.CreateCampaignsPageTwoGridContainer {
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1000px;
  display: grid;
}

.CreateCampaignsPageTwoMainSection {
  background-color: #f6f8fa;
  grid-column: 4 / span 13;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 50px 20px 20px 20px 40px 70px 20px 20px 20px 60px 10px 100px 10px 20px 10px 20px 15px 80px 20px 30px 20px -webkit-max-content 40px 40px;
  grid-template-rows: 50px 20px 20px 20px 40px 70px 20px 20px 20px 60px 10px 100px 10px 20px 10px 20px 15px 80px 20px 30px 20px max-content 40px 40px;
  /* 1    2    3    4    5    6    7    8    9    10   11   12   13   14   15   16  17    18    19   20   21  22            23   24 */
  min-height: 100vh;
}

.CreateCampaignsPageTwoTagsSection {
  display: grid;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  grid-column-gap: 50px;
  grid-row-gap: 10px;
  grid-row: 22;
  grid-column: 3;
}

/* Page Three Application Settings */

.CreateCampaignsPageThreeGridContainer {
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1200px;
  display: grid;
}

.CreateCampaignsPageThreeMainSection {
  background-color: #f6f8fa;
  grid-column: 4 / span 13;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 50px 20px 30px 30px 30px 40px 190px 40px 20px 40px 120px 20px 40px 100px 80px 40px 20px 100px 80px 40px;
  /* 1    2    3    4    5    6    7      8    9   10    11   12   13   14    15   16    17    18    19   20   21  22   23   24 */
  min-height: 100vh;
}

.CreateCampaignsPageThreeInputsWithIconContainer {
  display: grid;
  grid-template-columns: 1fr 5fr;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  grid-column-gap: 15px;
  border-radius: 5px;
}

.CreateCampaignsPageThreeInputsWithIconContainer div:nth-of-type(1) {
  padding-left: 7px;
}

.CreateCampaignsPageThreeInputsWithIconContainerUnselected {
  display: grid;
  grid-template-columns: 1fr 5fr;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255, 0.01);
  grid-column-gap: 15px;
  border-radius: 5px;
}

.CreateCampaignsPageThreeInputsWithIconContainerUnselected div:nth-of-type(1) {
  padding-left: 7px;
}

.CreateCampaignsPageThreePhotosSection {
  display: grid;
  grid-template-columns: repeat(3, minmax(50px, 1fr));

  grid-column-gap: 40px;
  grid-row-gap: 10px;
}

@media only screen and (max-width: 1000px) {
  .CreateCampaignsLeftSection {
    display: none;
  }

  .CreateCampaignsChoosePhotoContainer {
    grid-column: 9;
  }

  .CreateCampaignsThumbnail {
    grid-row: 1;
    grid-column: 1 / span 16;
    overflow: hidden;
    width: 100%;
  }

  .CreateCampaignsMainSection {
    grid-row: 2;
    grid-column: 1 / span 16;
    background-color: #f6f8fa;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 50px 75px 20px 200px 50px 75px 20px 75px 5px 20px 70px 30px;
    /* 1   2    3     4    5    6    7     8    9   10   11   12 */
    min-height: 100vh;
  }

  .CreateCampaignsPageTwoMainSection {
    background-color: #f6f8fa;
    grid-column: 1 / span 16;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 50px 20px 20px 20px 40px 70px 20px 20px 20px 60px 10px 100px 10px 20px 10px 20px 15px 80px 20px 30px 20px -webkit-max-content 80px 40px;
    grid-template-rows: 50px 20px 20px 20px 40px 70px 20px 20px 20px 60px 10px 100px 10px 20px 10px 20px 15px 80px 20px 30px 20px max-content 80px 40px;
    /* 1    2    3    4    5    6    7    8    9    10   11   12   13   14   15   16  17    18    19   20   21  22   23   24 */
    min-height: 100vh;
  }

  .CreateCampaignsPageTwoTagsSection {
    display: grid;
    grid-template-columns: repeat(3, minmax(50px, 1fr));
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    grid-row: 22;
    grid-column: 3;
  }

  .CreateCampaignsPageThreeMainSection {
    background-color: #f6f8fa;
    grid-column: 1 / span 16;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 50px 20px 30px 30px 30px 40px 190px 40px 20px 40px 120px 20px 40px -webkit-min-content 40px 40px 20px -webkit-max-content 30px -webkit-max-content;
    grid-template-rows: 50px 20px 30px 30px 30px 40px 190px 40px 20px 40px 120px 20px 40px min-content 40px 40px 20px max-content 30px max-content;
    /* 1    2    3    4    5    6    7      8    9   10    11   12   13     14          15     16    17    18            19       20         21  22   23   24 */
    min-height: 100vh;
  }

  .CreateCampaignsPageThreeBasicInfoSection {
    font-size: 12px;
  }

  .CreateCampaignsPageThreeInputsWithIconContainer {
    display: grid;
    grid-template-columns: 1fr 5fr;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);
    grid-column-gap: 15px;
  }

  .CreateCampaignsPageThreeInputsWithIconContainer div:nth-of-type(1) {
    padding-left: 7px;
  }

  .CreateCampaignsPageThreeProfilesSection {
    font-size: 12px;
  }

  .CreateCampaignsPageThreeInputsWithIconContainerUnselected {
    display: grid;
    grid-template-columns: 1fr 5fr;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255, 0.01);
    grid-column-gap: 15px;
  }

  .CreateCampaignsPageThreeInputsWithIconContainerUnselected
    div:nth-of-type(1) {
    padding-left: 7px;
  }

  .CreateCampaignsPageThreePhotosSection {
    display: grid;
    grid-template-columns: repeat(3, minmax(50px, 1fr));
    grid-column-gap: 40px;
    grid-row-gap: 10px;
  }

  .CreateCampaignInputIconContainerMobile {
    min-width: 100px;
  }
}

.datepicker {
  height: 40px;
  outline: none;
  border: none;
}

/* Reset Password Styles */
.ResetPasswordModalGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 40px 80px 80px 80px 80px 80px 80px 80px 80px;
  text-transform: capitalize;
  width: 600px;
  height: 220px;
}

.ResetPasswordDialogTitle {
  text-align: center;
  grid-row: 1;
  grid-column: 1 / span 16;
  align-self: center;
  font-weight: 800;
  font-size: 24px;
}

.ResetPasswordRunwaySupplyIcon {
  grid-row: 1;
  grid-column: 4 / span 4;
  align-self: center;
}

.ResetPasswordRunwaySupplyIconWidth {
  width: 48px;
}

.ResetPasswordDialogContent {
  grid-row: 2;
  grid-column: 4 / span 10;
  align-self: center;
}

.ResetPasswordDialogActions {
  grid-row: 3;
  grid-column: 4 / span 10;
  padding-top: 15px;
}

.ResetPasswordDialogActions button {

  background: #ff4742;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff4742;
  display: inline-block;
  padding: 14px;
  display: block;
  border-radius: 5px;
  width: 100%;
  text-transform: uppercase;
}

/* forgot password media  queries */

@media only screen and (max-width: 600px) {
  .ResetPasswordModalGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 80px 80px 80px 80px 80px 80px 80px 80px 80px;
    text-transform: uppercase;
    width: calc(100vw - 40px);
    height: 300px;
  }

  .ResetPasswordRunwaySupplyIconWidth {
    width: 44px;
  }

  .ResetPasswordRunwaySupplyIcon {
    grid-row: 1;
    grid-column: 1 / span 3;
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: 20px;
  }

  .ResetPasswordDialogTitle {
  }

  .ResetPasswordDialogContent {
    grid-row: 2;
    grid-column: 1 / span 16;
  }

  .ResetPasswordDialogActions {
    grid-column: 1 / span 16;
  }

  .ResetPasswordDialogActions button {
    background: #ff4742;
    font-weight: 800;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff4742;
    display: inline-block;
    padding: 14px;
    display: block;
    border-radius: 5px;
    width: 100%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: black !important;
  color: black !important;
}

.Dropdown-control {
  height: 40px !important;
  border: none !important;
  font-size: 12px;
}
.is-selected {
  color: black !important;
}
.Dropdown-placeholder {
  color: #828282;
  padding-right: 5px;
  padding-top: 5px;
  font-size: 12px;
}

.ApplicationGridContainer {
  display: grid;
  grid-template-rows: 170px 1fr;
  grid-row-gap: 12px;
}

.ApplicationCoverPhoto {
  overflow: hidden;
}

.ApplicationLeftContent {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-column: 1 / span 3;
  grid-template-rows: 60px -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: 60px max-content max-content max-content max-content;
  grid-row-gap: 15px;
  align-items: center;
}

.ApplicationMainContent {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-column: 4 / span 10;
  grid-row-gap: 30px;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

.MobileCampaignDetails {
  display: none;
}
/* .ReactCrop img {
  height: 100%;
  max-width: none;
  width: 100%;
}

.cropper-canvas {
  transform:none;
  transform: translateX(0px) !important;
  width: 100% !important;
  height: 100% !important;

}



.cropper-container {

}




*/

.cropper-canvas {
  max-width: 150px !important;
}

.maincropper div {
  width: 150px !important;
  height: 300px !important;
}

.cropper-canvas img {
}

.cropper-hidden {
  height: auto !important;
  width: auto !important;
}

.cropper-container {
  width: 150px !important;
  height: 300px !important;
}

.application-description-section {
  grid-column: 4 / span 10;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 25px;
}

.Model-Requirements-Header {
  grid-column: 1 / span 16;
  justify-self: center;
  font-size: 24px;
}

.Model-Application-Header {
  font-weight: 800;
}

.Application-Basic-Information-Header {
  grid-column: 2 / span 14;
  font-size: 14px;
}

.ModelRequirementsBoxes {
  grid-template-columns: repeat(3, 100px);
  justify-items: center;
  grid-column: 1 / span 16;
  justify-self: center;
  display: grid;
  grid-auto-rows: 90px;
}

.application-description-section {
  grid-column: 2 / span 14;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.Model-Requirements-Header {
  grid-column: 1 / span 16;
  justify-self: center;
  font-size: 24px;
  font-weight: 800;
}

.Dropdown-control {
  font-size: 9px;
}

.Model-Application-Header {
  font-weight: 800;
  grid-column: 1 / span 16;
  justify-self: center;
  font-size: 24px;
}

.Application-Basic-Information-Header {
  grid-column: 2 / span 14;
  font-size: 20px;
  font-weight: 100;
}

.Application-Basic-Information-Section {
  grid-column: 2 / span 14;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.Application-Gender-Section {
  grid-column: 2 / span 14;
  display: grid;
  grid-template-columns: auto auto calc(100vw - 50px);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
}

.Application-Gender-Header {
  font-size: 20px;
  font-weight: 100;
}

.Application-Social-Media-Header {
  grid-column: 2 / span 14;
  font-size: 20px;
  font-weight: 100;
}

.Application-Social-Profiles-Section {
  grid-column: 2 / span 14;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.Application-Photos-Header {
  grid-column: 2 / span 14;
  font-size: 20px;
  font-weight: 100;
}

.Application-Photos-Section {
  grid-column: 2 / span 14;
}

.Application-Profile-Picture-Header {
  font-size: 20px;
  font-weight: 100;
  grid-column: 2 / span 14;
}

.Profile-Picture-Section {
  grid-column: 2 / span 14;
}

@media only screen and (max-width: 800px) {
  .ApplicationMainContent {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-column: 1 / span 16;
    grid-row-gap: 30px;
    grid-template-rows:
      0px -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content 20px -webkit-max-content -webkit-max-content -webkit-max-content
      -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content 50px 10px -webkit-max-content 50px 50px 175px 100px;
    grid-template-rows:
      0px max-content max-content max-content max-content max-content 20px max-content max-content max-content
      max-content max-content max-content max-content max-content max-content max-content max-content max-content 50px 10px max-content 50px 50px 175px 100px;
    /*10            11           12         13           14       15           16             17      18 */
  }
  .ApplicationLeftContent {
    display: none;
  }
  .ModelRequirementsBoxes {
    grid-template-columns: repeat(3, 100px);
    justify-items: center;
  }

  .MobileCampaignDetails {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-row: 3;
    font-size: 14px;
    grid-row-gap: 10px;
  }

  .application-description-section {
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 25px;
    letter-spacing: 0.5px;
  }

  .Model-Requirements-Header {
    justify-self: center;
    font-size: 24px;
    font-weight: 800;
  }

  .Dropdown-control {
    font-size: 9px;
  }

  .Dropdown-placeholder {
    padding: 0;
  }

  .Model-Application-Header {
    font-weight: 800;
    grid-column: 1 / span 16;
    justify-self: center;
    font-size: 24px;
  }

  .Application-Basic-Information-Header {
    grid-column: 2 / span 14;
    font-size: 20px;
    font-weight: 100;
  }

  .Application-Basic-Information-Section {
    grid-column: 2 / span 14;
  }

  .Application-Gender-Section {
    grid-column: 2 / span 14;
    display: grid;
    grid-template-columns: auto auto calc(100vw - 50px);
    grid-column-gap: 20px;
    grid-row-gap: 30px;
  }

  .Application-Gender-Header {
    font-size: 20px;
    font-weight: 100;
  }

  .Application-Social-Media-Header {
    grid-column: 2 / span 14;
    font-size: 20px;
    font-weight: 100;
  }

  .Application-Social-Profiles-Section {
    grid-column: 2 / span 14;
  }

  .Application-Photos-Header {
    grid-column: 2 / span 14;
    font-size: 20px;
    font-weight: 100;
  }

  .Application-Photos-Section {
    grid-column: 2 / span 14;
  }

  .Application-Profile-Picture-Header {
    font-size: 20px;
    font-weight: 100;
    grid-column: 2 / span 14;
  }

  .Profile-Picture-Section {
    grid-column: 2 / span 14;
  }
}




.HeaderNotFound {
  font-size: 24px;
}

@media only screen and (max-width: 320px) {
  .HeaderNotFound {
    font-size: 16px;
  }
}


#NetworkUpperSection {
  grid-column: 1 / span 16;
  grid-row: 1;
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  align-self: center;
  grid-row-gap: 10px;
}


.ModelNetworkHeader {
  grid-column: 1/span 16;
  justify-self: center;
  font-size:24px;
  font-weight:800;
  padding-top: 40px;
}

.ModelNetworkSearch {
  grid-column: 2;
}

.ModelNetworkSearch {
  justify-self:center;
  padding-top: 10px;
}

@media only screen and (max-width: 1000px) {
  #NetworkUpperSection {
    grid-column: 1 / span 16;
    grid-row: 1;
    display: grid;
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
    align-self: center;
    grid-row-gap: 10px;
  }

  .ModelNetworkHeader {
    grid-column: 1/span 16;
    justify-self: center;
    font-size:24px;
    font-weight:800;
    padding-top: 40px;
  }


  .ModelNetworkSearch {
    grid-column: 1;
  }
}

@media only screen and (max-width: 600px)  {
    .ModelNetworkHeader {
      display: none;
    }

}





/* old css below
.NetworkGridContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 80px 40px minmax(700px, max-content);
  background-color: #F6F8FA;
  height: 100vh;
}

.NetworkTableHeaders {
  display:grid;
  grid-template-columns:repeat(6,1fr);
  align-self: center;
}

.NetworkMainTable {
  margin-top: 20px;
  background-color: #fff;
  border: .5px solid #D3D3D3;
  display:grid;
  grid-template-columns:  repeat(6,1fr);
  grid-auto-rows: max-content;
  padding: 4px 10px;
}

.NetworkCampaignCount {

}

.network-model-options-modal {
  position: absolute;
  align-items: center;
  display: none;
  background-color:#fff;
  grid-template-columns:80px;
  border: .5px solid black;
}


@media only screen and (max-width: 1000px) {
  .NetworkGridContainer div {
    font-size: 10px;
  }
  #network-profile-picture {

  }

  .network-model-options-modal {
    position: absolute;
    align-items: center;
    display: none;
    background-color:#fff;
    grid-template-columns:50px;
    border: .5px solid black;
  }


  .NetworkTableHeaders {
    margin-top: 20px;
    display:grid;
    grid-template-columns:140px 60px 60px repeat(2,1fr);
  }

  .NetworkMainTable {
    margin-top: 20px;
    background-color: #fff;
    border: .5px solid #D3D3D3;
    display:grid;
    grid-template-columns: 140px 60px 60px repeat(2,1fr);
    grid-auto-rows: max-content;
  }

  .NetworkTagsHeader {
    display: none;
  }

  .NetworkTags {
    display: none;
  }

  .NetworkCampaignCountHeader {
    justify-self: center;
  }

  .NetworkCampaignCount {
    justify-self: center;
    padding-left: 0px;
  }

  .CityHeader {
    justify-self:center;
  }

  .CityData {
    justify-self: center;
  }

  .NetworkPreviousButton{
    height: 18px;
  }

  .NetworkNextButton {
    height: 18px;
  }
}
*/

.NetworkModelDetailsContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-template-rows: 0px 0px 0px 0px minmax(50px,-webkit-min-content) 10px 20px 20px minmax(50px, auto) 40px 20px 20px  minmax(50px, auto) 40px 20px 20px minmax(150px, auto);
  grid-template-rows: 0px 0px 0px 0px minmax(50px,min-content) 10px 20px 20px minmax(50px, auto) 40px 20px 20px  minmax(50px, auto) 40px 20px 20px minmax(150px, auto)
                    /* cp   gap   |    |        campaign-details     |   |                  |                               |
                                title  |                            Gap  |                  |                             Profiles
                                    arrrows                              |                  |
                                                                  Basic Info Title          |
                                                                                        Basic Info      Prof Title
                                                      5                    7                  9             11            13
                                    */
}


.ProfileGridContainer {
  display: grid;
  grid-template-columns: repeat(16 , 1fr);
  grid-template-rows: 30px 250px 0px 300px 0px 300px;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .ProfileGridContainer {
    display: block;
  }
}

.ProfileInput {
  border: 1px solid black;
  overflow: hidden;
  margin-top: 10px;
  height: 40px;
  background-color: #fff;
  padding: 0px;
  padding-left: 10px;
}

.ProfileSaveInput {
  border: none;
  color:white;
  overflow: hidden;
  margin-top: 10px;
  height: 40px;
  background-color: #00cb93;
  width: 100%;
}

.ProfileDeleteInput {
  border: none;
  color:white;
  overflow: hidden;
  margin-top: 10px;
  height: 40px;
  background-color: #ff4d56;
  width: 100%;
}

.ProfileCardContainer {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  padding: 14px;
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  max-width: -webkit-fill-available;
}
/*

display: grid;
grid-template-columns: 1fr;
grid-column: 1 / span 16;
border: 1px solid #ccc;
border-radius: 5px;
overflow: hidden;
margin-top: 10px;
height: 50px;
background: #fff;
z-index: 2;

*/



@media (max-width: 414px) {
  .Thank-You-For-Applying-Main-Heading {
    font-size: 24px;
  }

  .Thank-You-For-Applying-Sub-Heading {
    font-size: 11px;
  }
}


@media (max-width: 375px) {
  .Thank-You-For-Applying-Main-Heading {
    font-size: 18px;
  }

  .Thank-You-For-Applying-Sub-Heading {
    font-size: 10px;
  }
}

@media (max-width: 320px) {
  .Thank-You-For-Applying-Main-Heading {
    font-size: 16px;
  }

  .Thank-You-For-Applying-Sub-Heading {
    font-size: 8px;
  }
}

