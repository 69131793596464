.ViewApplicantsContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  background-color: #F6F8FA;
  grid-template-rows: 200px 40px auto minmax(50px,min-content) minmax(50px, auto) auto 20px 40px 450px;
}

.Campaign-Applicants-Campaign-Details {
  display: grid;
  grid-column: 2 / span 14;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
}

.Campaign-Applicants-Hire-Limit {
  display: grid;
  grid-template-columns: 30px auto;
}

.Campaign-Applicants-Deadline {
  display: grid;
  grid-template-columns: 30px auto;
}

.Campaign-Applicants-Table-Headers {
  grid-column:2 / span 14;
  grid-row:5;
  display:grid;
  grid-template-columns: 2fr repeat(6, 1fr);
}

.Campaign-Applicants-Table {
  grid-column:1 / span 16;
  grid-row:6;
  display:grid;
  grid-template-columns:30px 2fr repeat(6, 1fr);
  background-color: #fff;
  margin-bottom: 10px;
}

.Campaign-Applicants-Models-Container {
   grid-row: 6;
   grid-column:2 / span 14;
 }

.Campaign-Applicants-Email {

}

.Campaign-Applicants-Full-Name{
  grid-column: 2;
  grid-row: 1;
  align-self: flex-end;
  white-space:nowrap;
}

.Campaign-Applicant-Social-Icon {
  width: 18px;
  height: 15px;
}

div[target=Applicants-View-Header] {
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 800;
}

.NextAndPreviousSection {
  display:grid;
  grid-template-columns: 10px 20px 10px;
  grid-row: 2;
  justify-self: flex-end;
  margin-right:12px;
}

.Campaign-Applicants-Modal-Styles{
  align-items:center;
  display: none;
  position:absolute;
  background-color:#fff;
  grid-template-columns:100px;
  border: .5px solid black;
}

.Campaign-Applicants-Model-Details-Option {
  padding-bottom: 7px;
  padding-left: 5px;
  text-transform:capitalize;
}

.Campaign-Applicants-Model-Decline-Option{
  padding-left: 5px;
  color: red;
}

.Campaign-Applicants-Socials-Section {
   align-self:  center;
   display: grid;
  grid-column-gap: 5px;
  grid-template-columns:repeat(4, 1fr);
}

.Campaign-Applicants-Campaign-Details-Item {
  display: grid;
  grid-template-columns: 30px 1fr;
}

.Campaign-Applicants-Gender {
  padding-left: 10px;
}
@media only screen and (min-width: 1600px) {
  .Campaign-Applicants-Campaign-Details {

  }

}

@media only screen and (max-width: 1000px) {

.Campaign-Applicants-Campaign-Details  {
    grid-column: 1 / span 16;
  }
  .Campaign-Applicants-Table-Headers {
    grid-column: 1 / span 16;
  }
  .Campaign-Applicants-Models-Container {
    grid-column: 1 / span 16;
  }
}

@media only screen and (max-width: 450px) {
  .Campaign-Applicants-Campaign-Details {
    display: grid;
    grid-template-columns: repeat(3,minmax(max-content,1fr));
    grid-column: 1 / span 16;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-items: center;
    font-size: 12px;
    padding-top: 12px;
  }

  .Campaign-Applicants-Campaign-Details-Item {
    display: grid;
    grid-template-columns: 30px 1fr;
  }
  .Campaign-Applicants-Campaign-Details-Item.Four {
    display: none;
  }
 .Campaign-Applicants-Campaign-Details-Item.Five {
     display: none;
   }
  /* So the details can actually be centered must define an equal width on each div */
  .Campaign-Applicants-Hire-Limit {
    display: none;
  }

  .Campaign-Applicants-Deadline {
    display: none;
  }
  .Campaign-Applicants-Table-Headers {
    grid-template-columns: 70px 130px 70px 50px 80px;
    grid-column:1 / span 16;
    font-size: 10px;
  }

  .Campaign-Applicants-Table {
    grid-template-columns: 30px 170px 70px 25px 80px;
    font-size: 10px;
  }

  .Campaign-Applicants-Date-Applied-Header {
    display: none;
  }

  .Campaign-Applicants-Date-Applied {
    display: none;
  }

  .Campaign-Applicants-Table-City {

  }

  .Campaign-Applicants-Gender {
    text-align: center;
  }

  .Campaign-Applicants-Table-Campaign-Count {
    justify-self:center;
    display:none;
  }

  .Campaign-Applicants-Socials-Header {
    display: none;
  }

  .Campaign-Applicants-Socials-Section {
    display: none;
  }

  .Campaign-Applicants-Models-Container {
    grid-column: 1 / span 16;
  }

  div[target=Applicants-View-Header] {
    font-size: 24px !important;
  }
  .Campaign-Applicants-Model-NEP {

  }
  .Campaign-Applicants-Email {
    display: none;
  }

  .Campaign-Applicants-Full-Name{
    grid-column: 2;
    grid-row: 1 / span 2;
    align-self: center;
    white-space:nowrap;
  }

  .Campaign-Applicant-Social-Icon {
    width: 12px;
    height: 12px;
  }

  .Campaign-Applicants-Options-Section {
    grid-column: 5;
    grid-row: 1
  }

  .NextAndPreviousSection {
    grid-column: 1;
    justify-self: flex-start;
    margin: 0;
  }

  .Campaign-Applicants-Modal-Styles{
    align-items:center;
    display: none;
    position:absolute;
    background-color:#fff;
    grid-template-columns:40px;
    font-size: 10px;
    font-weight: 800;
    text-transform: capitalize;
    border: .5px solid black;

  }

  .Campaign-Applicants-Model-Details-Option {
    padding: 0px;
    padding-top: 10px;
    text-align: center;
    text-transform:capitalize;
  }
  .Campaign-Applicants-Model-Decline-Option{
    padding: 0px;
    padding-top: 10px;
    text-align: center;
    padding-bottom: 10px;
  }

  .Campaign-Applicants-PreviousButtonSize {
    height: 18px;
  }
  .CampaignApplicants-NextButtonSize {
        height: 18px;
  }
}


@media only screen and (max-width: 320px) {
  .Campaign-Applicants-Table-Headers {
    grid-template-columns: 70px 80px 70px 50px 80px;
    grid-column:1 / span 16;
    font-size: 10px;
  }

  .Campaign-Applicants-Table {
    grid-template-columns: 30px 120px 70px 25px 80px;
    font-size: 10px;
  }
  .Campaign-Applicants-Full-Name{
    grid-column: 2;
    grid-row: 1 / span 2;
    align-self: center;
    white-space:pre-wrap;
  }
}
