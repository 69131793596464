textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
}

/* slider */
.rc-slider-dot {
  background-color: black !important;
  display: none !important;
}
.rc-slider-handle {
  background-color: black !important;
  border: solid 2px black !important;
}
.rc-slider-track {
  background-color: black !important;
}

.rc-slider-dot-active {
  border: solid 2px black !important;
  display: block !important;
}

/* Page One Create Campaign */
.CreateCampaignsPageOneGridContainer {
}

.CreateCampaignsThumbnail {
  grid-row: 1;
  grid-column: 4 / span 13;
  overflow: hidden;
  width: 100%;
}

.CreateCampaignsChoosePhotoContainer {
  grid-column: 14 / span 2;
  grid-row: 1;
  z-index: 100;
  margin-top: 80;
  height: 32px;
}

.CreateCampaignsLeftSection {
  grid-row: 1 / span 2;
  grid-column: 1 / span 3;
  max-width: 100%;
  background-color: #24292e;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 10px 30px 20px 40px 20px 40px 45px 40px 60px 100px 10px 10px 10px;
  /* 1    2    3    4    5    6    7    8      9     10 */
  min-height: 100vh;
}

.CreateCampaignsMainSection {
  grid-row: 2;
  grid-column: 4 / span 13;
  background-color: #f6f8fa;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 50px 75px 20px 200px 50px 75px 20px 75px 5px 20px 70px 30px;
  /* 1   2    3     4    5    6    7     8    9   10   11   12 */
  min-height: calc(100vh - 170px);
}

.CreateCampaignTitleInput {
  display: grid;
  grid-template-columns: 1fr;
  grid-column: 1 / span 16;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  height: 50px;
  background: #fff;
  z-index: 2;
}

.CreateCampaignTitleInput input {
  width: 100%;
  padding: 7px 10px 11px 13px;
  display: block;
  border: none;
  font-size: 16px;
  box-sizing: border-box;
}

.CreateCampaignInputIconContainer {
  display: grid;
  grid-column: 1 / span 16;
  grid-template-columns: 60px 4fr;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  min-width: 250px;
}

.CreateCampaignInputIconContainer:focus-within input {
  grid-column: 1/4;
  padding-left: 55px;
}

.CreateCampaignInputIconContainer:focus-within input::placeholder {
  color: transparent;
}

.CreateCampaignInputIconContainer img {
  padding: 0.5rem;
  grid-row: 1;
  grid-column: 1/2;
  z-index: 2;
}

.CreateCampaignInputIconContainer input {
  border: 0;
  padding: 0.5rem;
  grid-column: 2/4;
  grid-row: 1;
  outline: none;
}
.react-datepicker__input-container {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  padding-top: 0;
}

.react-datepicker__input-container:focus-within input {
  padding: 8px;
  padding-top: 0;
}

.CoverPhotoLabel {
  cursor: pointer;
}
/* Page Two Model Specs */
.CreateCampaignsPageTwoGridContainer {
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1000px;
  display: grid;
}

.CreateCampaignsPageTwoMainSection {
  background-color: #f6f8fa;
  grid-column: 4 / span 13;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 50px 20px 20px 20px 40px 70px 20px 20px 20px 60px 10px 100px 10px 20px 10px 20px 15px 80px 20px 30px 20px max-content 40px 40px;
  /* 1    2    3    4    5    6    7    8    9    10   11   12   13   14   15   16  17    18    19   20   21  22            23   24 */
  min-height: 100vh;
}

.CreateCampaignsPageTwoTagsSection {
  display: grid;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  grid-column-gap: 50px;
  grid-row-gap: 10px;
  grid-row: 22;
  grid-column: 3;
}

/* Page Three Application Settings */

.CreateCampaignsPageThreeGridContainer {
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1200px;
  display: grid;
}

.CreateCampaignsPageThreeMainSection {
  background-color: #f6f8fa;
  grid-column: 4 / span 13;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 50px 20px 30px 30px 30px 40px 190px 40px 20px 40px 120px 20px 40px 100px 80px 40px 20px 100px 80px 40px;
  /* 1    2    3    4    5    6    7      8    9   10    11   12   13   14    15   16    17    18    19   20   21  22   23   24 */
  min-height: 100vh;
}

.CreateCampaignsPageThreeInputsWithIconContainer {
  display: grid;
  grid-template-columns: 1fr 5fr;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  grid-column-gap: 15px;
  border-radius: 5px;
}

.CreateCampaignsPageThreeInputsWithIconContainer div:nth-of-type(1) {
  padding-left: 7px;
}

.CreateCampaignsPageThreeInputsWithIconContainerUnselected {
  display: grid;
  grid-template-columns: 1fr 5fr;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255, 0.01);
  grid-column-gap: 15px;
  border-radius: 5px;
}

.CreateCampaignsPageThreeInputsWithIconContainerUnselected div:nth-of-type(1) {
  padding-left: 7px;
}

.CreateCampaignsPageThreePhotosSection {
  display: grid;
  grid-template-columns: repeat(3, minmax(50px, 1fr));

  grid-column-gap: 40px;
  grid-row-gap: 10px;
}

@media only screen and (max-width: 1000px) {
  .CreateCampaignsLeftSection {
    display: none;
  }

  .CreateCampaignsChoosePhotoContainer {
    grid-column: 9;
  }

  .CreateCampaignsThumbnail {
    grid-row: 1;
    grid-column: 1 / span 16;
    overflow: hidden;
    width: 100%;
  }

  .CreateCampaignsMainSection {
    grid-row: 2;
    grid-column: 1 / span 16;
    background-color: #f6f8fa;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 50px 75px 20px 200px 50px 75px 20px 75px 5px 20px 70px 30px;
    /* 1   2    3     4    5    6    7     8    9   10   11   12 */
    min-height: 100vh;
  }

  .CreateCampaignsPageTwoMainSection {
    background-color: #f6f8fa;
    grid-column: 1 / span 16;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 50px 20px 20px 20px 40px 70px 20px 20px 20px 60px 10px 100px 10px 20px 10px 20px 15px 80px 20px 30px 20px max-content 80px 40px;
    /* 1    2    3    4    5    6    7    8    9    10   11   12   13   14   15   16  17    18    19   20   21  22   23   24 */
    min-height: 100vh;
  }

  .CreateCampaignsPageTwoTagsSection {
    display: grid;
    grid-template-columns: repeat(3, minmax(50px, 1fr));
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    grid-row: 22;
    grid-column: 3;
  }

  .CreateCampaignsPageThreeMainSection {
    background-color: #f6f8fa;
    grid-column: 1 / span 16;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 50px 20px 30px 30px 30px 40px 190px 40px 20px 40px 120px 20px 40px min-content 40px 40px 20px max-content 30px max-content;
    /* 1    2    3    4    5    6    7      8    9   10    11   12   13     14          15     16    17    18            19       20         21  22   23   24 */
    min-height: 100vh;
  }

  .CreateCampaignsPageThreeBasicInfoSection {
    font-size: 12px;
  }

  .CreateCampaignsPageThreeInputsWithIconContainer {
    display: grid;
    grid-template-columns: 1fr 5fr;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);
    grid-column-gap: 15px;
  }

  .CreateCampaignsPageThreeInputsWithIconContainer div:nth-of-type(1) {
    padding-left: 7px;
  }

  .CreateCampaignsPageThreeProfilesSection {
    font-size: 12px;
  }

  .CreateCampaignsPageThreeInputsWithIconContainerUnselected {
    display: grid;
    grid-template-columns: 1fr 5fr;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255, 0.01);
    grid-column-gap: 15px;
  }

  .CreateCampaignsPageThreeInputsWithIconContainerUnselected
    div:nth-of-type(1) {
    padding-left: 7px;
  }

  .CreateCampaignsPageThreePhotosSection {
    display: grid;
    grid-template-columns: repeat(3, minmax(50px, 1fr));
    grid-column-gap: 40px;
    grid-row-gap: 10px;
  }

  .CreateCampaignInputIconContainerMobile {
    min-width: 100px;
  }
}

.datepicker {
  height: 40px;
  outline: none;
  border: none;
}
