.FullWidth {
  width: 100%;
}

.HomepageGrid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: min-content;
  grid-row-gap: 72px;
  /*
      REGARDING GRID-TEMPLATE-ROWS
      200PX IS THE HEIGHT OF THE FIRST ROW  ---> NAVIGATION / HEADERS ( THIS ROW IS PROBABLY NOT NEEDED ANYMORE
        because I refactored Navigation to its own component although if I remove the first 200px I would have
        to do some refactoring and update every pages respective row. Might do it later but theirs no problem
        having that 200px their for now.
      )
      600PX IS THE HEIGHT OF THE SECOND ROW ---> PAGE 1 CONTENT
      300PX IS THE HEIGHT OF THE THIRD ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      500PX IS THE HEIGHT OF THE FOURTH ROW ---> PAGE 2 CONTENT
      300PX IS THE HEIGHT OF THE FIFTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      900PX IS THE HEIGHT OF THE SIXTH ROW ---> PAGE 3 CONTENT
      300PX IS THE HEIGHT OF THE SEVENTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      600PX IS THE HEIGHT OF THE EIGHTH ROW ---> PAGE 4 CONTENT
      250PX IS THE HEIGHT OF THE NINTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      780PX IS THE HEIGHT OF THE TENTH ROW ---> PAGE 5 CONTENT
      100PX IS THE HEIGHT OF THE ELEVENTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      600PX IS THE HEIGHT OF THE TWELTH ROW ---> PAGE 6 CONTENT
      150PX IS THE HEIGHT OF THE THIRTEENTH ROW IM JUST USING THIS AS A ROW TO GIVE SPACE LIKE THE ORIGINAL PROTOTYPE
      400PX IS THE HEIGHT OF THE FOURTEENTHROW ---> FOOTER
   */
  width: 100%;
}

/* GRID MEDIA QUERIES */
@media only screen and (max-width: 1300px) {
  .HomepageGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-auto-rows: min-content;
    width: 100%;
  }
}

/* GRID MEDIA QUERIES */
@media only screen and (max-width: 600px) {
  .HomepageGrid {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-auto-rows: min-content;
    width: 100%;
  }
}

/* GRID MEDIA QUERIES */

.PageOne {
  margin-top: 72px;
  grid-column: 1 / span 16;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: min-content;
}

.ContentPageOne {
  align-self: center;
  grid-column: 2 / span 5;
  min-width: 240px;
}

.ContentPageOneHeadingInfo {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
}

.ContentPageOneSubHeadingInfo {
  margin-bottom: 15px;
}

.ContentPageOneSectionTwo {
  grid-column: 16;
}

.ContentPageOneEmailInputMobile {
  display: none;
}

.ContentPageOneEmailInput {
  grid-column: 1 / span 13;
}

.ContentPageOneEmailInput input {
  width: 100%;
  margin: 0;
  padding: 0 25px;
  padding-bottom: 5px;
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
  font-family: Karla;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.57px;
  line-height: 14px;
  border: 1px solid #000;
  background: none;
  outline: none;
  height: 42px;
}

.ContentPageOneEmailInput button {
  visibility: hidden;
  width: 0;
  height: 0;
  border: none;
  padding: 0;
}
.ContentPageOneEmailInput label {
  display: none;
}

.ContentPageOneEmailInput input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #000 inset;
  -webkit-text-fill-color: #fff;
}

#CampaignsImage {
  width: 640px;
}

/* PAGE ONE MEDIA QUERIES */

@media only screen and (max-width: 960px) {
  .PageOne {
    grid-column: 1 / span 16;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
  }

  #CampaignsImage {
    content: url("../Assets/LandingPage/campaigns-mobile-desktop-view.jpg");
  }

  .ContentPageOneHeadingInfo {
    margin-bottom: 15px;
  }

  .ContentPageOne {
    grid-column: 4 / span 10;
    text-align: center;
    justify-self: center;
    width: auto;
  }

  .ContentPageOneSectionTwo {
    grid-column: 1 / span 16;
    display: flex;
    flex-direction: column;
  }

  .ContentPageOneEmailInput {
    justify-self: normal;
    grid-column: 2 / span 12;
  }

  .ContentPageOneEmailInputMobile {
    display: block;
    height: 44px;
    align-self: center;
    width: 300px;
    margin-right: 60px;
  }

  .ImagePageOneColumns {
    align-self: center;
  }

  #CampaignsImage {
    width: 396px;
  }

  .ContentPageOneEmailInputWeb {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  #CampaignsImage {
    width: 340px;
  }
}

@media only screen and (max-width: 550px) {
  .ContentPageOneEmailInput {
    grid-column: 2 / span 12;
    justify-self: normal;
  }
}

.PageTwo {
  grid-column: 1 / span 16;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  height: -webkit-fill-available;
}

.ImageTwo {
  grid-column: 9 / span 15;
}

.ImageTwo img {
  object-fit: cover;
  width: 100%;
}

.IconPageTwo {
  background-color: rgba(255, 255, 255, 0);
  margin: 0;
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.ContentPageTwo {
  grid-column: 2 / span 5;
  display: grid;
  align-self: flex-start;
}

.ContentPageTwoHeadingInfo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* PAGE TWO MEDIA QUERIES */
@media only screen and (min-width: 1000px) {
  .ImageTwo {
    grid-column: 16;
  }
  #CreateCampaignImage {
    width: 640px;
  }
}

@media only screen and (max-width: 750px) {
  .ImageTwo {
    object-fit: cover;
    width: 100%;
    grid-column: 1 / span 16;
    margin-top: 55px;
  }

  .ContentPageTwo {
    grid-column: 4 / span 10;
  }

  .IconPageTwo {
    background-color: rgba(255, 255, 255, 0);
    margin: 0;
    padding: 5px;
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  #CreateCampaignImage {
    content: url("../Assets/LandingPage/mobile-desktop-create-campaign.jpg");
  }
}

.PageThree {
  grid-column: 1 / span 16;
  background-color: rgb(232, 253, 255);
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  /*
    Since we had to change the background of this entire sub page I need to add a grid within a grid to make the layout with the background
  */
  align-items: center;
  height: -webkit-fill-available;
  min-height: 800px;
}

.ImagePageThree {
  object-fit: cover;
  width: 100%;
  grid-column: 2 / span 4;
}

.ContentPageThree {
  grid-column: 10 / span 6;
}

.InnerContentPageThree {
  display: grid;
  justify-items: flex-end;
  text-align: right;
}

.AnalyticsIcon {
  justify-self: flex-end;
  margin-bottom: 10px;
}

.ContentPageThreeHeadingInfo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* PAGE THREE MEDIA QUERIES */
@media only screen and (max-width: 750px) {
  .ContentPageThree {
    grid-column: 1 / span 16;
    grid-row: 1;
  }

  .ImagePageThree {
    object-fit: cover;
    width: 100%;
    grid-column: 1 / span 16;
    text-align: center;
  }

  .ImagePageThree img {
    width: 300px;
  }

  .InnerContentPageThree {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    text-align: left;
  }

  .AnalyticsIcon {
    grid-row: 1;
    justify-self: flex-start;
    grid-column: 4 / span 10;
    margin-bottom: 10px;
  }

  .ContentPageThreeHeadingInfo {
    grid-row: 2;
    font-size: 28px;
    font-style: bold;
    margin-bottom: 15px;
    grid-column: 4 / span 12;
    margin-top: 5px;
  }

  .ContentPageThreeSubheadingInfo {
    grid-row: 3;
    grid-column: 4 / span 10;
    margin-top: 15px;
  }

  #AnalyticsImage {
    content: url("../Assets/LandingPage/analytics-mobile.jpg");
  }
}

.PageFour {
  grid-column: 1 / span 16;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  height: -webkit-fill-available;
  min-height: 800px;
}

.PageFourContent {
  grid-column: 2 / span 6;
  align-self: center;
  margin-bottom: 200px;
}

.ContentPageFourHeadingInfo {
  font-size: 28px;
  font-style: bold;
  margin-bottom: 15px;
}

.NetworkIcon {
  margin-bottom: 10px;
}

.PageFourImage {
  grid-column: 8 / span 12;
  align-self: center;
}

.PageFourImage img {
  height: 100%;
  width: 100%;
}

/* Page Four Media Queries */
@media only screen and (min-width: 1000px) {
  .PageFourImage {
    grid-column: 16;
  }

  .PageFourImage img {
    width: 640px;
  }
}

@media only screen and (max-width: 750px) {
  .PageFourContent {
    grid-column: 4 / span 10;
    align-self: center;
    margin-bottom: 35px;
  }

  .ContentPageFourHeadingInfo {
    font-size: 28px;
    font-style: bold;
    margin-bottom: 15px;
  }

  .NetworkIcon {
    margin-bottom: 10px;
  }

  .PageFourImage {
    grid-column: 1 / span 16;
  }

  .PageFourImage img {
    height: 100%;
    width: 100%;
    content: url("../Assets/LandingPage/network-image-mobile.jpg");
  }
}

.PageFive {
  grid-column: 1 / span 16;
  /* Neeed Another Grid Inside To Make The Layout */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 100px 0px;
}

.PageFiveLeftCollage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 50px;
}

.PageFiveLeftCollage img {
  height: 144px;
  width: 90px;

}

.CollageLeftOne {
  grid-column: 1;
  grid-row: 2;
}

.CollageLeftTwo {
  grid-column: 1;
  grid-row: 5;
}

.CollageLeftThree {
  grid-column: 1;
  grid-row: 8;
}

.CollageLeftFour {
  grid-column: 1;
  grid-row: 11;
}

.CollageLeftFive {
  grid-column: 2;
  grid-row: 1;
}

.CollageLeftSix {
  grid-column: 2;
  grid-row: 4;
}

.CollageLeftSeven {
  grid-column: 2;
  grid-row: 7;
}

.CollageLeftEight {
  grid-column: 2;
  grid-row: 10;
}

.CollageLeftNine {
  grid-column: 3;
  grid-row: 3;
}

.CollageLeftTen {
  grid-column: 3;
  grid-row: 6;
}

.CollageLeftEleven {
  grid-column: 3;
  grid-row: 9;
}

.CollageLeftTwelve {
  grid-column: 3;
  grid-row: 12;
}

.PageFiveRightCollage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 50px;
  grid-column-gap: 12px;
}

.PageFiveRightCollage img {
  height: 144px;
  width: 90px;
}

.CollageRightOne {
  grid-column: 1;
  grid-row: 5;
}

.CollageRightTwo {
  grid-column: 1;
  grid-row: 8;
}

.CollageRightThree {
  grid-column: 1;
  grid-row: 11;
}

.CollageRightFour {
  grid-column: 2;
  grid-row: 3;
}

.CollageRightFive {
  grid-column: 2;
  grid-row: 6;
}

.CollageRightSix {
  grid-column: 2;
  grid-row: 9;
}

.CollageRightSeven {
  grid-column: 2;
  grid-row: 12;
}

.CollageRightEight {
  grid-column: 3;
  grid-row: 2;
}

.CollageRightNine {
  grid-column: 3;
  grid-row: 5;
}

.CollageRightTen {
  grid-column: 3;
  grid-row: 8;
}

.CollageRightEleven {
  grid-column: 3;
  grid-row: 11;
}

.CollageRightTwelve {
  grid-column: 3;
  grid-row: 12;
}

.PageFiveMission {
  align-self: center;
  margin-bottom: 40px;
  font-size: 20px;
}

.OurMissionTextHeading {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1100px) {
  .PageFiveMission {
    align-self: center;
    font-size: 20px;
  }
}

/* Page Five Media Queries */
@media only screen and (max-width: 900px) {
  .PageFive {
    grid-column: 1 / span 16;
    /* Neeed Another Grid Inside To Make The Layout */
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    background-color: rgb(32, 51, 65);
  }

  .PageFiveLeftCollage {
    display: none;
  }

  .PageFiveRightCollage {
    display: none;
  }

  .PageFiveRightCollage img {
    height: 144px;
    width: 90px;
  }

  .PageFiveMission {
    grid-column: 4 / span 10;
    align-self: center;
    margin-bottom: 40px;
    color: white;
  }

  .OurMissionTextHeading {
    margin-bottom: 20px;
  }
}

.PageSix {
  grid-column: 1 / span 16;
  justify-self: center;
}

.PageSixContent {
  text-align: center;
}

.PricingHeading {
  font-size: 32px;
  font-style: bold;
  margin-bottom: 15px;
}

.PricingSubheader {
}

.PricingModal {
  margin-top: 40px;
}

/* Page six Media Queries */
@media only screen and (max-width: 750px) {
  .PageSix {
    margin: 0 12px;
  }
  #PricingModal {
    height: 100%;
    width: 100%;
  }
}

.Footer {
  grid-column: 1 / span 16;
  background-color: rgb(210, 227, 230);
  /* Using A Grid To Make The Layout for the Four Parts Of The Footer */
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: min-content;
}

.FooterTrademarks {
  grid-row: 2;
  grid-column: 2 / span 4;
}

.FooterTradeMarkIcon {
  margin-bottom: 10px;
}

.FooterTrademarkHeading {
  letter-spacing: 0.57px;
  line-height: 24px;
  font-size: 24px;
  font-style: bold;
  margin-bottom: 15px;
}

.FooterAbout {
  grid-row: 3;
  grid-column: 7 / span 2;
}

.FooterAboutHeading {
  font-weight: bold;
}

.FooterAbout div {
  margin-bottom: 15px;
}

.FooterLegal {
  grid-row: 3;
  grid-column: 10 / span 2;
}

.FooterLegalHeading {
  font-weight: bold;
}

.FooterLegal div {
  margin-bottom: 15px;
}

.FooterGetInTouch {
  grid-row: 3;
  grid-column: 13 / span 2;
}

.FooterGetInTouchHeading {
  font-weight: bold;
}

.FooterGetInTouch div {
  margin-bottom: 15px;
}
